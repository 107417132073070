import { ChangeEvent, FC, useState } from "react";
import "./styles/text-area.css";

interface InputProps {
  type: "text" | "number" | "email" | "password";
  label: string;
  value: string | number | undefined;
  name: string;
  placeholder: string;
  error: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Input: FC<InputProps> = ({
  type,
  label,
  value,
  name,
  placeholder,
  error,
  disabled,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string | number | undefined>(
    value as string | number | undefined
  );
  return (
    <div className="text-area-wrapper">
      <label htmlFor={label}>{label}</label>
      <textarea
        id={label}
        value={inputValue}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange(e);
        }}
        disabled={disabled}
      />
      {error && <p className="error">Input filed can't be empty!</p>}
    </div>
  );
};

export default Input;
