import React from "react";
import { useLocation } from "react-router-dom";
import "./styles/list-menu.css";

const ListItem: React.FC<{
  name: string;
  icon: string;
  link: string;
}> = (props: { name: string; icon: string; link: string }) => {
  const location = useLocation();
  const isActive = location.pathname === props.link;
  return (
    <a className={`list-item ${isActive ? "active" : ""}`} href={props.link}>
      <img className="icon" src={props.icon} alt={props.name} />
      <div className="name">{props.name}</div>
    </a>
  );
};
class ListMenu extends React.Component<{
  links: { name: string; iconSVG: string; link: string }[];
}> {
  render() {
    return (
      <div className="list-menu">
        {this.props.links.map((link) => (
          <ListItem
            name={link.name}
            icon={link.iconSVG}
            link={link.link}
            key={link.name}
          />
        ))}
      </div>
    );
  }
}

export default ListMenu;
