import React, { useEffect, useState } from "react";
import Carousel from "antd/lib/carousel";
import Image from "antd/lib/image";
import Skeleton from "antd/lib/skeleton";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import "./styles/product-card.css";

export interface ProductCardInterface {
  id: string;
  title: string;
  description: string;
  minPrice: number;
  maxPrice: number;
  image: string;
  images: string[];
  category: {
    id: string;
    name: string;
  };
  brand: {
    id: string;
    name: string;
  };
  conditionsSizes?: {
    isNew: boolean;
    size: string;
    price: number;
    inventoryIds: string[];
  }[];
}

export interface productPageInterface {
  id: string;
  name: string;
  description: string;
  image: string;
  categoryId: string;
  brandId: string;
  originalPrice: number;
  items: {
    id: string;
    isNew: boolean;
    size: string;
    price: number;
    images: string[];
  }[];
}

export interface extandedProductPageInterface {
  id: string;
  title: string;
  description: string;
  image: string;
  category: {
    id: string;
    name: string;
  };
  brand: {
    id: string;
    name: string;
  };
  items: {
    id: string;
    isNew: boolean;
    size: string;
    price: number;
    images: string[];
  }[];
}

const ProductCard: React.FC<{
  product: ProductCardInterface;
  cartItemIds: string[];
  addToCart: (inventoryItemId: string) => void;
}> = (props) => {
  const [availabeSizes, setAvailabeSizes] = useState<
    {
      size: string;
      price: number;
      isNew: boolean;
      inventoryIds: string[];
    }[]
  >(
    props.product.conditionsSizes
      ? props.product.conditionsSizes.map((size) => ({
          size: size.size,
          price: size.price,
          isNew: size.isNew,
          inventoryIds: [],
        }))
      : []
  );
  const [availableItems, setAvailabeItems] = useState<string[]>([]);
  const {
    id,
    title,
    brand,
    category,
    description,
    minPrice,
    maxPrice,
    image,
    images,
    conditionsSizes,
  } = props.product;
  const cartItemIds = props.cartItemIds;
  const navigate = useNavigate();
  const addToCart = props.addToCart;

  const [selectedCondition, setSelectedCondition] = useState<boolean | null>(
    null
  );

  const [selectedSize, setSelectedSize] = useState<string | null>(null);

  const [currentPrice, setCurrentPrice] = useState<number | null>(null);

  const [currentId, setCurrentId] = useState<string | null>(null);

  useEffect(
    () => {
      if (
        selectedCondition !== null &&
        selectedSize !== null &&
        conditionsSizes
      ) {
        setAvailabeItems(
          conditionsSizes
            .filter(
              (size) =>
                size.isNew === selectedCondition && size.size === selectedSize
            )
            .map((size) => size.inventoryIds)
            .flat()
        );
        const size = conditionsSizes.find(
          (size) =>
            size.isNew === selectedCondition && size.size === selectedSize
        );
        if (size) {
          const availableItems = size.inventoryIds.find(
            (id) => !cartItemIds.includes(id)
          );
          setCurrentPrice(size.price);
          if (availableItems !== undefined) {
            setCurrentId(availableItems);
          } else {
            const availableItems = size.inventoryIds[0];
            setCurrentId(availableItems);
          }
        }
      }
    },
    // eslint-disable-next-line
    [cartItemIds, conditionsSizes, selectedSize]
  );

  useEffect(() => {
    setSelectedSize(null);
    setSelectedCondition(null);
    setCurrentPrice(null);
    setCurrentId(null);
    setAvailabeItems([]);
    setAvailabeSizes([]);
  }, [props.product]);

  return (
    <div className="product-card">
      <Image.PreviewGroup>
        <Carousel infinite={false} adaptiveHeight={true}>
          <Image
            src={image}
            alt={description}
            height="200px"
            placeholder={
              <Skeleton.Image
                active={true}
                style={{
                  width: "250px",
                  height: "200px",
                }}
              />
            }
          />
          {images
            .reduce((acc: string[], image) => {
              if (!acc.includes(image)) {
                acc.push(image);
              }
              return acc;
            }, [])
            .map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={title}
                height="200px"
                placeholder={
                  <Skeleton.Image
                    active
                    style={{
                      width: "250px",
                      height: "200px",
                    }}
                  />
                }
              />
            ))}
        </Carousel>
      </Image.PreviewGroup>
      <div className="data">
        <div
          className="title"
          onClick={() => {
            navigate(
              `/products?categories=${category.id}&brands=${brand.id}&id=${id}`
            );
            window.scrollTo(0, 0);
          }}
        >
          {brand.name} <br />
          <Marquee
            gradient={false}
            speed={30}
            gradientColor="var(--secondary-color)"
            style={{
              color: "gray",
            }}
          >
            <div className="product-name" style={{ marginRight: "1rem" }}>
              {title}
            </div>
          </Marquee>
        </div>
        <div className="padding">
          {conditionsSizes && (
            <>
              <div className="data-title conditions-title">
                Available Conditions
              </div>
              <div className="data-data conditions">
                {conditionsSizes &&
                  Array.from(
                    new Set(conditionsSizes.map((size) => size.isNew))
                  ).map((isNew, index) => (
                    <button
                      className={
                        "condition-button" +
                        (isNew === selectedCondition ? " active" : "")
                      }
                      key={index}
                      onClick={() => {
                        setSelectedCondition(isNew);
                        setAvailabeSizes(
                          conditionsSizes.filter((size) => size.isNew === isNew)
                        );
                        setAvailabeItems(
                          conditionsSizes
                            .filter(
                              (size) =>
                                size.isNew === isNew &&
                                (selectedSize !== null
                                  ? size.size === selectedSize
                                  : true)
                            )
                            .map((size) => size.inventoryIds)
                            .flat()
                        );
                        setSelectedSize(null);
                        setCurrentPrice(null);
                        setCurrentId(null);
                      }}
                    >
                      {isNew ? "New" : "Pre-Owned"}
                    </button>
                  ))}
              </div>
            </>
          )}
          {availabeSizes && selectedCondition !== null && (
            <>
              <div className="data-title sizes-title">Availabe Sizes</div>
              <div className="data-data sizes">
                {availabeSizes
                  .sort((a, b) => parseFloat(a.size) - parseFloat(b.size))
                  .map((size, index) => (
                    <button
                      className={size.size === selectedSize ? " active" : ""}
                      onClick={() => {
                        setSelectedSize(size.size);
                      }}
                      key={index}
                    >
                      {size.size}
                    </button>
                  ))}
              </div>
            </>
          )}
          {availableItems &&
          selectedSize !== null &&
          selectedCondition === false ? (
            <>
              <div className="data-title items-title">Availabe Items</div>
              <div className="data-data items">
                {availableItems.map((id, index) => (
                  <button
                    className={id === currentId ? " active" : ""}
                    onClick={() => {
                      setCurrentId(id);
                    }}
                    key={index}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </>
          ) : null}
        </div>
        <div className="price">
          {currentPrice
            ? `$${currentPrice}`
            : minPrice === maxPrice
            ? `$${minPrice}`
            : `$${minPrice} - $${maxPrice}`}
        </div>
        <div className="buttons">
          <button
            className={currentId === null ? "disabled" : ""}
            onClick={() => (currentId !== null ? addToCart(currentId) : null)}
          >
            Add To Cart
          </button>
          <button
            onClick={() => {
              navigate(
                `/products?categories=${category.id}&brands=${brand.id}&id=${id}`
              );
              window.scrollTo(0, 0);
            }}
          >
            View Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
