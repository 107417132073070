import React, { useState } from "react";
import "./styles/cart.css";
import assets from "../assets";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

export interface StoreCartInterface {
  // id: string;
  productId: string;
  inventoryId: string;
  name: string;
  size: string;
  condition: string;
  image: string;
  price: number;
}

const Cart: React.FC<{
  cartItems: StoreCartInterface[];
  removeItem: (id: string) => Promise<boolean>;
  clearCart: () => void;
  closeTab: () => void;
}> = (props) => {
  // eslint-disable-next-line
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" ||
      (!("darkMode" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
  );

  const cartItems = props.cartItems;

  const navigate = useNavigate();

  return (
    <div className={"cart" + (isDarkMode ? " dark-theme" : " light-theme")}>
      {cartItems.length === 0 ? (
        <div className="empty-cart">
          <img src={assets.emptyCart} alt="Empty Cart" />
          <h3>Your cart is empty.</h3>
        </div>
      ) : (
        <>
          <div className="cart-items">
            {cartItems.map((item) => (
              <div key={item.inventoryId} className="cart-item">
                <img src={item.image} alt={item.name} />
                <div className="cart-item-details">
                  <h3
                    className="produt-name"
                    onClick={() => {
                      // Redirect to product page
                      navigate(`/products?id=${item.productId}`);
                      props.closeTab();
                    }}
                  >
                    <Marquee gradient={false} speed={30}>
                      <div style={{ marginRight: "1rem" }}>{item.name}</div>
                    </Marquee>
                  </h3>
                  <p>Size: {item.size}</p>
                  <p>Condition: {item.condition}</p>
                  <p className="price">${item.price}</p>
                </div>
                <div className="actions">
                  <button
                    className="remove-item"
                    onClick={async () => {
                      // Remove item from cart
                      await props.removeItem(item.inventoryId);
                    }}
                  >
                    <img src={assets.removeFromCartBtn} alt="Remove" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="cart-items-total">
            <h3>
              Total: $
              {cartItems.reduce((acc, item) => {
                return acc + item.price;
              }, 0)}
            </h3>
          </div>
          <div className="actions">
            <button
              onClick={() => {
                // Redirect to request purchase page
                navigate("/request-purchase");
                window.scrollTo(0, 0);
                props.closeTab();
              }}
            >
              Request Purchase
            </button>
            <button
              onClick={() => {
                // Clear cart
                props.clearCart();
              }}
            >
              Clear Cart
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
