import { ConfigProvider, Tabs, TabsProps } from "antd";
import "./styles/statistics.css";
import React from "react";
import WebsiteStats from "./WebsiteStats";

const Statistics: React.FC<{}> = (props) => {
  const tabsItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Google Statistics Report",
      children: (
        <iframe
          title="Google Statistics Report"
          width="100%"
          height="100%"
          src="https://lookerstudio.google.com/embed/u/0/reporting/5bfa5d48-5d7b-43f3-a8f2-a9e69628248a/page/kIV1C?params=%7B%22dp56%22:%22a174160805w441686284%22%7D"
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          style={{ border: "none" }}
        ></iframe>
      ),
    },
    {
      key: "2",
      label: "Website Statistics Report",
      children: <WebsiteStats />,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#ffffff",
          colorBgBase: "#1e1e1e",
          colorTextBase: "#4b4b4b",
          colorText: "#ffffff",
          colorTextPlaceholder: "#1e1e1e",
          colorTextHeading: "#ffffff",
          colorBorder: "#4b4b4b",
          colorBorderSecondary: "#4b4b4b",
          colorBgBlur: "#1e1e1e",
          colorBorderBg: "#1e1e1e",
          controlOutline: "rgba(125, 104, 0, 0.27)",
          fontFamily: `"Noto Sans", sans-serif`,
        },
      }}
    >
      <Tabs
        defaultActiveKey="1"
        items={tabsItems}
        style={{
          padding: "1rem",
          height: "100%",
          width: "100%",
        }}
      />
    </ConfigProvider>
  );
};

export default Statistics;
