import dashboard from "./Dashboard.svg";
import logo from "./Logo.svg";
import mobileLogo from "./mobile-logo.svg";
import orders from "./Orders.svg";
import statistics from "./Statistics.svg";
import hamburgerMenu from "./HamburgerMenu.svg";
import addItem from "./AddItem.svg";
import editItem from "./EditItem.svg";
import returnBack from "./Return.svg";
import categories from "./Categories.svg";
import brands from "./Brands.svg";
import products from "./Products.svg";
import close from "./Close.svg";
import minimize from "./Minimize.svg";
import shoppingCart from "./ShoppingCart.svg";
import remove from "./Remove.svg";
import location from "./Location.svg";
import openingHours from "./OpeningHours.svg";
import email from "./Email.svg";
import instagram from "./Instagram.svg";
import left from "./Left.svg";
import right from "./Right.svg";
import filter from "./Filter.svg";
import view from "./View.svg";
import noResults from "./NoResults.svg";
import requestSubmitted from "./requestSubmitted.svg";
import error404 from "./Error404.svg";
import accessDenied from "./NotAllowed.svg";
import emptyCart from "./EmptyCart.svg";
import removeFromCartBtn from "./RemoveFromCart.svg";
import logOut from "./LogOut.svg";
import naSystems from "./NA-Systems.svg";
import video from "./video.mov";

const assets = {
  dashboard,
  logo,
  mobileLogo,
  orders,
  statistics,
  hamburgerMenu,
  addItem,
  editItem,
  returnBack,
  categories,
  brands,
  products,
  close,
  minimize,
  requestSubmitted,
  view,
  shoppingCart,
  remove,
  location,
  openingHours,
  email,
  instagram,
  left,
  right,
  filter,
  noResults,
  error404,
  accessDenied,
  emptyCart,
  removeFromCartBtn,
  logOut,
  naSystems,
  video,
};

export default assets;
