import React, { useEffect, useState } from "react";
import NavigationBar from "./NavigationBar";
import Items from "./ItemsSection";
import "./styles/store.css";
import { ProductCardInterface } from "./ProductCard";
import Footer from "./Footer";
import { HomeOutlined } from "@ant-design/icons";
import Breadcrumb from "antd/lib/breadcrumb";
import Drawer from "antd/lib/drawer";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Cart, { StoreCartInterface } from "./Cart";
import ProductPage, { productPageInterface } from "./ProductPage";
import Filter from "./Filter";
import Spin from "antd/lib/spin";
import AboutUs from "./AboutUs";
import assets from "../assets";
import PrivacyPolicy from "./PrivacyPolicy";
import ShippingPolicy from "./ShippingPolicy";
import ReturnsPolicy from "./ReturnsPolicy";
import FAQ from "./FAQ";
import RequestPurchase from "./RequestPurchase";
import { message } from "antd";
import Home from "./Home";
import ConfigProvider from "antd/lib/config-provider";

export type categoriesType = {
  id: string;
  name: string;
  subItems: categoriesType[];
};

export type brandsType = {
  id: string;
  logoURL: string;
  name: string;
};

const Store: React.FC<{}> = (props) => {
  const [currentTitle, setCurrentTitle] = useState<string | null>(null);
  const [orderComplete, setOrderComplete] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [currentPage, setCurrentPage] = useState<
    ProductCardInterface[] | productPageInterface | null
  >(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [dealsCount, setDealsCount] = useState<number>(0);
  const [breadcrubItems, setBreadcrubItems] = useState<
    { href: string | undefined; title: string | JSX.Element }[]
  >([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchResults, setSearchResults] = useState<ProductCardInterface[]>(
    []
  );
  const [drawerState, setDrawerState] = useState<"cart" | "filter" | null>(
    null
  );
  const [filtersData, setFiltersData] = useState<{
    brands: brandsType[];
    categories: categoriesType[];
    allCategories: {
      id: string;
      name: string;
    }[];
    sizes: string[];
  }>({ brands: [], categories: [], sizes: [], allCategories: [] });
  const [filters, setFilters] = useState<{
    id?: string;
    page?: string;
    isNew?: boolean;
    brands?: string;
    categories?: string;
    sizes?: string;
    deals?: boolean;
  }>({});

  const [cart, setCart] = useState<StoreCartInterface[]>([]);

  const navigate = useNavigate();

  const onOpenCart = () => {
    setOpenDrawer(true);
    document.body.style.overflow = "hidden";
    setDrawerState("cart");
  };

  const onOpenFilter = () => {
    setOpenDrawer(true);
    document.body.style.overflow = "hidden";
    setDrawerState("filter");
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDrawerState(null);
    document.body.style.overflow = "auto";
  };

  const removeItem = async (id: string) => {
    const newCart = cart.filter((item) => item.inventoryId !== id);
    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
    message.success("Item removed from cart", 3);
    return true;
  };

  const fetchData = () => {
    Promise.all([
      fetch("https://api.ybs-closet.com/brands")
        .then((response) => response.json())
        .then((data: brandsType[]) => {
          setFiltersData((prevData) => ({
            ...prevData,
            brands: data,
          }));
        })
        .catch((error) => {
          console.error("Error fetching brands:", error);
        }),
      fetch("https://api.ybs-closet.com/categories")
        .then((response) => response.json())
        .then((data: categoriesType[]) => {
          setFiltersData((prevData) => ({
            ...prevData,
            categories: data,
          }));
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        }),
      fetch("https://api.ybs-closet.com/categories/all")
        .then((response) => response.json())
        .then((data: categoriesType[]) => {
          setFiltersData((prevData) => ({
            ...prevData,
            allCategories: data,
          }));
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        }),
      fetch("https://api.ybs-closet.com/inventory/sizes")
        .then((response) => response.json())
        .then((data: string[]) => {
          setFiltersData((prevData) => ({
            ...prevData,
            sizes: data,
          }));
          // console.log(data);
        })
        .catch((error) => {
          console.error("Error fetching sizes:", error);
        }),
      fetch("https://api.ybs-closet.com/products/deals/count")
        .then((response) => response.json())
        .then((data: { count: number }) => {
          if (data.count > 0) {
            setDealsCount(data.count);
          }
        })
        .catch((error) => {
          console.error("Error fetching deals count:", error);
        }),
    ]);
    const cart = localStorage.getItem("cart");
    if (cart) {
      setCart(JSON.parse(cart));
    } else {
      localStorage.setItem("cart", JSON.stringify([]));
      setCart([]);
    }
  };

  const location = useLocation();

  const deepSearch = (
    categories: categoriesType[],
    id: string
  ): categoriesType | null => {
    for (const category of categories) {
      if (category.id === id) return category;
      const result: categoriesType | null = deepSearch(category.subItems, id);
      if (result !== null) return result;
    }
    return null;
  };

  useEffect(
    () => {
      const breadcrumbItems: {
        href: string | undefined;
        title: string | JSX.Element;
      }[] = [];

      switch (location.pathname) {
        case "/products":
          const newFilters: { [key: string]: string } = {};
          location.search
            .split("?")[1]
            .split("&")
            .forEach((pair) => {
              const [key, value] = pair.split("=");
              newFilters[key] = value;
            });
          const caseKeys = ["isNew", "deals", "categories", "brands", "id"];

          for (const key of caseKeys) {
            if (newFilters.hasOwnProperty(key)) {
              const value = newFilters[key];
              if (value !== undefined && value !== "") {
                switch (key) {
                  case "id":
                    const product = currentPage
                      ? (currentPage as productPageInterface).title
                      : null;
                    if (product)
                      breadcrumbItems.push({
                        href: product ? undefined : "/",
                        title: product ? product : value.toString(),
                      });
                    break;
                  case "categories":
                    if (value.toString().split(",").length > 1) break;
                    const category = deepSearch(
                      filtersData.categories,
                      value as string
                    );
                    if (category) {
                      breadcrumbItems.push({
                        href: "/products?categories=" + value,
                        title: category.name,
                      });
                    }
                    break;
                  case "brands":
                    if (value.toString().split(",").length > 1) break;
                    const brandName = filtersData.brands.find(
                      (brand) => brand.id === value
                    )?.name;
                    if (brandName) {
                      breadcrumbItems.push({
                        href: "/products?brands=" + value,
                        title: brandName,
                      });
                    }
                    break;
                  case "isNew":
                    breadcrumbItems.push({
                      href: "/products?isNew=" + value,
                      title:
                        value === "true" ? "New Products" : "Prewoned Products",
                    });
                    break;
                  case "deals":
                    breadcrumbItems.push({
                      href: "/products?deals=" + value,
                      title: "Deals",
                    });
                    break;
                  default:
                    break;
                }
                break;
              }
            }
          }
          break;
        case "/search":
          breadcrumbItems.push({
            href: "/search",
            title: "Search Results",
          });
          break;
        case "/request-purchase":
          breadcrumbItems.push({
            href: "/request-purchase",
            title: "Request Purchase",
          });
          break;
        case "/faq":
          breadcrumbItems.push({
            href: "/faq",
            title: "FAQs",
          });
          break;
        case "/about-us":
          breadcrumbItems.push({
            href: "/about-us",
            title: "About Us",
          });
          break;
        case "/privacy-policy":
          breadcrumbItems.push({
            href: "/privacy-policy",
            title: "Privacy Policy",
          });
          break;
        case "/shipping-policy":
          breadcrumbItems.push({
            href: "/shipping-policy",
            title: "Shipping Policy",
          });
          break;
        case "/returns-policy":
          breadcrumbItems.push({
            href: "/returns-policy",
            title: "Returns Policy",
          });
          break;
        default:
          break;
      }

      breadcrumbItems.unshift({
        href: "/",
        title: (
          <div className="name">
            <HomeOutlined /> YB's
          </div>
        ),
      });

      const lastBreadcrumbItem = breadcrumbItems.pop();
      if (lastBreadcrumbItem) {
        if (typeof lastBreadcrumbItem.title === "string")
          setCurrentTitle(lastBreadcrumbItem.title.toString());
        breadcrumbItems.push(lastBreadcrumbItem);
      }
      setBreadcrubItems(breadcrumbItems);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, location.pathname, location.search]
  );

  useEffect(
    () => {
      if (location.pathname !== "/search") setSearchResults([]);
      setPageNotFound(false);
      setCurrentPage(null);
      const newFilters: { [key: string]: string } = {};
      const query = location.search;
      const pathname = location.pathname;
      switch (pathname) {
        case "/products":
          if (query && query !== "") {
            query
              .split("?")[1]
              .split("&")
              .forEach((pair) => {
                const [key, value] = pair.split("=");
                newFilters[key] = value;
              });
            for (const [key, value] of Object.entries(newFilters)) {
              const filter = filters[key as keyof typeof filters];
              if (filter !== undefined || filter !== value) {
                setFilters({
                  isNew:
                    newFilters.isNew === "true"
                      ? true
                      : newFilters.isNew === "false"
                      ? false
                      : undefined,
                  brands: newFilters.brands,
                  categories: newFilters.categories,
                  sizes: newFilters.sizes,
                  id: newFilters.id,
                  page: newFilters.page || "1",
                  deals: newFilters.deals === "true" ? true : undefined,
                });
                break;
              }
            }
          } else {
            setFilters({
              page: "1",
            });
          }
          break;
        case "/request-purchase":
          if (query && query !== "") {
            // get specificly id from query
            const id = query.split("?")[1].split("=")[1];
            setFilters({ id });
          } else {
            setFilters({});
          }
          break;
        default:
          setFilters({});
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, location.search]
  );

  // const getServerStatus = () => {
  //   return fetch("https://api.ybs-closet.com/status", {
  //     method: "GET",
  //   }).then((response) => {
  //     if (response.status === 200) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // };

  useEffect(() => {
    // Wait for server to be up
    // while (!getServerStatus()) {
    //   setTimeout(() => {
    //     console.log("Retrying to connect to server...");
    //   }, 100);
    // }
    fetchData();
  }, []);

  const RequestPurchaseFunction = async (
    cart: string[],
    customer: {
      name: string;
      email: string;
      phone: string;
      instagram: string;
      address: string;
      city: string;
      state: string;
      zipCode: string;
      zelle: string;
    },
    delivery: boolean
  ) => {
    if (cart.length === 0) {
      message.error("No items in cart", 3);
      return false;
    }
    if (customer.name === "") {
      message.error("Name is required", 3);
      return false;
    }
    if (customer.email === "") {
      message.error("Email is required", 3);
      return false;
    }
    if (customer.phone === "") {
      message.error("Phone number is required", 3);
      return false;
    }
    if (delivery) {
      if (customer.address === "") {
        message.error("Address is required", 3);
        return false;
      }
      if (customer.city === "") {
        message.error("City is required", 3);
        return false;
      }
      if (customer.state === "") {
        message.error("State is required", 3);
        return false;
      }
      if (customer.zipCode === "") {
        message.error("Zip code is required", 3);
        return false;
      }
    }
    if (customer.zelle === "") {
      message.error("Zelle information is required", 3);
      return false;
    }
    return await fetch("https://api.ybs-closet.com/cart/request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cart: cart,
        customer: customer,
        delivery: delivery,
      }),
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          message.success("Request submitted successfully!", 3);
          localStorage.setItem("cart", JSON.stringify([]));
          setCart([]);
          setOrderComplete(true);
          return true;
        } else {
          throw new Error("Failed to submit request");
        }
      })
      .catch(() => {
        message.error("Failed to submit request. Please try again later.", 3);
        return false;
      });
  };

  const addToCart = async (inventoryId: string) => {
    if (cart.find((item) => item.inventoryId === inventoryId)) {
      message.info("Item already in cart", 3);
      return;
    }
    await fetch(`https://api.ybs-closet.com/inventory/${inventoryId}`, {
      method: "GET",
    })
      .then(async (res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          message.error("Error adding item to cart", 3);
        }
      })
      .then(async (res: StoreCartInterface) => {
        try {
          setCart([...cart, res]);
          localStorage.setItem("cart", JSON.stringify([...cart, res]));
          message.success("Item added to cart", 3);
        } catch {
          message.error("Error adding item to cart", 3);
        }
      })
      .catch((error) => {
        message.error("Error adding item to cart", 3);
      });
  };

  const clearCart = async () => {
    localStorage.setItem("cart", JSON.stringify([]));
    setCart([]);
    message.success("Cart cleared", 3);
  };

  const getCurrentPage = () => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment);
    const filterQuery = location.search;
    switch (pathSegments.length) {
      case 1:
        switch (pathSegments[0]) {
          // Product Page
          case "products":
            if (filters.id !== undefined) {
              fetch(`https://api.ybs-closet.com/products/${filters.id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
              })
                .then((response) => {
                  if (response.status === 404) {
                    setPageNotFound(true);
                    throw new Error("Page not found");
                  }
                  return response.json();
                })
                .then((data) => setCurrentPage(data as productPageInterface))
                .catch((error) => console.error(error));
            } else {
              fetch("https://api.ybs-closet.com/products" + filterQuery, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
              })
                .then((response) => {
                  if (response.status === 404) {
                    setPageNotFound(true);
                    throw new Error("Page not found");
                  }
                  return response.json();
                })
                .then((data) => {
                  setCurrentPage(data as ProductCardInterface[]);
                })
                .catch((error) => console.error(error));
            }
            break;
          default:
            break;
        }
        break;
      default:
        setCurrentPage(null);
        break;
    }
  };

  const getTotalItems = () => {
    fetch("https://api.ybs-closet.com/products/count" + location.search, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setTotalItems(data.count))
      .catch((error) => console.error(error));
  };

  useEffect(
    () => {
      if (location.pathname !== "/products") return;
      let query = "";
      let shouldNavigate = false;
      for (let [key, val] of Object.entries(filters)) {
        key = key.toLowerCase();
        val = val?.toString().toLowerCase() || "";
        if (val !== undefined && val !== "") {
          query += query === "" ? `?${key}=${val}` : `&${key}=${val}`;
          if (!location.search.toLowerCase().includes(`${key}=${val}`))
            shouldNavigate = true;
          else {
            const searchValue = location.search
              .split("?")[1]
              .split("&")
              .find((pair) => pair.includes(`${key}=`))
              ?.split("=")[1];
            if (searchValue !== val) shouldNavigate = true;
          }
        } else if (location.search.includes(`${key}=`)) shouldNavigate = true;
      }
      if (shouldNavigate) {
        navigate(location.pathname + query);
      }
      if (currentPage === null) {
        getCurrentPage();
        if (
          filters.id !== undefined ||
          filters.page !== "1" ||
          location.pathname !== "/products"
        )
          return;
        getTotalItems();
      }
      window.scrollTo(0, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const cartItemIds = cart.map((item) => item.inventoryId);

  return filtersData.brands.length === 0 ? (
    <div className="main-loader">
      <div className="image-container">
        {" "}
        <img
          className="loader-img-shining"
          src={assets.logo}
          alt="YB's Closet"
        />
      </div>
    </div>
  ) : (
    <>
      <div className="store">
        <NavigationBar
          brands={filtersData.brands}
          categories={filtersData.categories}
          cartSize={cart.length}
          dealsCount={dealsCount}
          onOpenCart={onOpenCart}
          onOpenFilter={onOpenFilter}
          filtersCount={
            Object.values({
              ...filters,
              id: undefined,
              page: undefined,
            }).filter((f) => f !== undefined && f !== "").length
          }
          updateFilters={(newFilters) => {
            setFilters(newFilters);
          }}
          currentPage={currentPage}
          onSearch={(search) => {
            if (search === "") {
              setSearchResults([]);
              return;
            } else if (search.length < 3) return;
            fetch(
              "https://api.ybs-closet.com/products/search?query=" + search,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
              }
            )
              .then((response) => response.json())
              .then((data) => setSearchResults(data))
              .catch((error) => console.error(error));
          }}
          searchResults={searchResults}
          onSearchBtnClick={() => {
            navigate("/search");
          }}
        />
        <Breadcrumb
          items={breadcrubItems}
          className="breadcrumb"
          style={{
            textTransform: "capitalize",
            fontSize: "1rem",
          }}
        />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#7d6900",
              colorBgBase: "#ffffff",
              colorTextBase: "#1e1e1e",
              colorText: "#1e1e1e",
              colorTextPlaceholder: "#1e1e1e",
              colorTextHeading: "#7d6900",
              colorBorder: "#7d6900",
              colorBorderSecondary: "#7d6900",
              colorBgBlur: "#ffffff",
              colorBorderBg: "#ffffff",
              controlOutline: "rgba(125, 104, 0, 0.27)",
              fontFamily: `"Noto Sans", sans-serif`,
            },
          }}
        >
          <Drawer
            title={
              drawerState
                ? drawerState[0].toUpperCase() + drawerState.slice(1)
                : ""
            }
            onClose={onCloseDrawer}
            open={openDrawer}
            style={{
              overflowY: "auto",
            }}
          >
            {drawerState === "cart" ? (
              <Cart
                cartItems={cart}
                removeItem={removeItem}
                clearCart={clearCart}
                closeTab={onCloseDrawer}
              />
            ) : drawerState === "filter" ? (
              <Filter
                data={filtersData}
                setFilters={(newFilters) => {
                  setFilters({
                    id: newFilters.id !== "" ? newFilters.id : undefined,
                    page: newFilters.page !== "" ? newFilters.page : undefined,
                    isNew: newFilters.isNew,
                    brands:
                      newFilters.brands !== "" ? newFilters.brands : undefined,
                    categories:
                      newFilters.categories !== ""
                        ? newFilters.categories
                        : undefined,
                    sizes:
                      newFilters.sizes !== "" ? newFilters.sizes : undefined,
                  });
                }}
                values={filters}
                onClose={onCloseDrawer}
              />
            ) : null}
          </Drawer>
        </ConfigProvider>
        {pageNotFound ? (
          <div className="not-found">
            <img
              src={assets.error404}
              alt="Error 404"
              className="not-found-img"
            />
            <h1>Page Not Found</h1>
          </div>
        ) : (
          <Routes>
            <Route path="/faq" element={<FAQ />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/returns-policy" element={<ReturnsPolicy />} />
            <Route
              path="/request-purchase"
              element={
                cart.length > 0 || filters.id ? (
                  <RequestPurchase
                    id={filters.id}
                    cartItems={cart}
                    removeItem={removeItem}
                    requestPurchase={RequestPurchaseFunction}
                  />
                ) : orderComplete ? (
                  <div className="request-submitted">
                    <img
                      src={assets.requestSubmitted}
                      alt="Request Submitted"
                      className="request-submitted-img"
                    />
                    <h1>Request Submitted</h1>
                    <p>We will review yout cart contact you within 24 hours.</p>
                  </div>
                ) : (
                  <div className="no-results">
                    <img
                      src={assets.noResults}
                      alt="No Results"
                      className="no-results-img"
                    />
                    <h1>No Items in Cart</h1>
                  </div>
                )
              }
            />
            <Route
              path="/search"
              element={
                searchResults.length > 0 ? (
                  <Items
                    title="Search Results"
                    items={searchResults}
                    totalItems={searchResults.length}
                    cartItemIds={cartItemIds}
                    addToCart={addToCart}
                    currentPage={1}
                    updatePage={() => {}}
                  />
                ) : (
                  <div className="no-results">
                    <img
                      src={assets.noResults}
                      alt="No Results"
                      className="no-results-img"
                    />
                    <h1>No Results Found</h1>
                  </div>
                )
              }
            />
            <Route
              path="/products"
              element={
                currentPage === null ? (
                  <div className="loader">
                    <Spin size="large" />
                  </div>
                ) : currentPage instanceof Array ? (
                  currentPage.length > 0 ? (
                    <>
                      <Items
                        title={currentTitle || undefined}
                        items={currentPage}
                        totalItems={totalItems}
                        cartItemIds={cartItemIds}
                        addToCart={addToCart}
                        currentPage={parseInt(filters.page || "1")}
                        updatePage={(page) => {
                          setFilters({ ...filters, page: page.toString() });
                        }}
                      />
                    </>
                  ) : (
                    <div className="no-results">
                      <img
                        src={assets.noResults}
                        alt="No Results"
                        className="no-results-img"
                      />
                      <h1>No Available Products</h1>
                    </div>
                  )
                ) : (
                  <ProductPage
                    data={currentPage}
                    addToCart={addToCart}
                    cartItemIds={cartItemIds}
                    clearCart={clearCart}
                  />
                )
              }
            />
            <Route
              path="/"
              element={
                <Home
                  addToCart={addToCart}
                  categories={filtersData.allCategories}
                  cartItemIds={cartItemIds}
                  filters={filters}
                />
              }
            />
            <Route
              path="/*"
              element={
                <div className="not-found">
                  <img
                    src={assets.error404}
                    alt="Error 404"
                    className="not-found-img"
                  />
                  <h1>Page Not Found</h1>
                </div>
              }
            />
          </Routes>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Store;
