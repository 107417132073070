import React from "react";
import "./styles/product-page.css";
import Tooltip from "antd/lib/tooltip";
import assets from "../assets";
import { Helmet } from "react-helmet-async";

export interface productItem {
  isNew: boolean;
  size: string;
  inventoryItems: {
    id: string;
    images: string[];
    price: number;
    description: string;
  }[];
}

export interface productPageInterface {
  id: string;
  title: string;
  description: string;
  image: string;
  category: {
    id: string;
    name: string;
  };
  brand: {
    id: string;
    name: string;
  };
  retailPrice?: number;
  items: productItem[];
}

const ProductPage: React.FC<{
  data: productPageInterface;
  cartItemIds: string[];
  addToCart: (inventoryItemId: string) => void;
  clearCart: () => void;
}> = (props) => {
  const [images, setImages] = React.useState<string[]>([]);
  const [imageIndex, setImageIndex] = React.useState(0);
  const [currentImage, setCurrentImage] = React.useState<string | null>(null);
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [currentCondition, setCurrentCondition] =
    React.useState<Boolean | null>(null);
  const [currentSize, setCurrentSize] = React.useState<string | null>(null);
  const [currentProduct, setCurrentProduct] =
    React.useState<productItem | null>(null);
  const [currentItem, setCurrentItem] = React.useState<{
    id: string;
    images: string[];
    price: number;
    description: string;
  } | null>(null);

  React.useEffect(
    () => {
      const prices = props.data.items
        .map((item) => item.inventoryItems.map((item) => item.price))
        .flat()
        .reduce(
          (acc: number[], val) => (acc.includes(val) ? acc : [...acc, val]),
          []
        );
      setMinPrice(Math.min(...prices));
      setMaxPrice(Math.max(...prices));
      setImages([
        props.data.image,
        ...props.data.items[0].inventoryItems
          .map((item) => item.images)
          .flat()
          .reduce(
            (acc: string[], val) => (acc.includes(val) ? acc : [...acc, val]),
            []
          ),
      ]);
      setCurrentImage(props.data.image);
      if (props.data.items.every((item) => item.isNew)) {
        setCurrentCondition(true);
      } else if (props.data.items.every((item) => !item.isNew)) {
        setCurrentCondition(false);
      }
    },
    // eslint-disable-next-line
    [props.data.items]
  );

  React.useEffect(
    () => {
      if (currentCondition === null) return;
      if (
        Array.from(
          new Set(
            props.data.items
              .filter((item) => item.isNew === currentCondition)
              .map((item) => item.size)
          )
        ).length === 1
      ) {
        const items = props.data.items.filter(
          (item) => item.isNew === currentCondition
        );
        if (items.length === 1) {
          setCurrentSize(items[0].size);
        }
      }
    },
    // eslint-disable-next-line
    [currentCondition]
  );

  React.useEffect(() => {
    if (
      currentSize === null ||
      currentCondition === null ||
      !props.data.items
    ) {
      setCurrentProduct(null);
    } else {
      const product = props.data.items.find(
        (item) => item.size === currentSize && item.isNew === currentCondition
      );
      if (product) {
        setCurrentProduct(product);
      } else {
        setCurrentProduct(null);
      }
    }
  }, [props.data.items, currentCondition, currentSize]);

  React.useEffect(() => {
    if (
      !currentProduct ||
      !currentProduct.inventoryItems ||
      !props.cartItemIds
    ) {
      setCurrentItem(null);
    } else {
      const item = currentProduct.inventoryItems.find(
        (item) => !props.cartItemIds.includes(item.id)
      );
      if (item) {
        setCurrentItem(item);
      } else {
        setCurrentItem(null);
      }
    }
  }, [props.cartItemIds, currentProduct]);

  React.useEffect(
    () => {
      setImageIndex(0);
      if (currentItem !== null) {
        setImages([props.data.image, ...currentItem.images]);
      } else {
        if (currentProduct !== null)
          setImages([
            props.data.image,
            ...currentProduct?.inventoryItems
              .map((item) => item.images)
              .flat()
              .reduce(
                (acc: string[], val) =>
                  acc.includes(val) ? acc : [...acc, val],
                []
              ),
          ]);
      }
    },
    // eslint-disable-next-line
    [currentItem]
  );

  React.useEffect(() => {
    if (images.length >= 1)
      setCurrentImage(images.length > 1 ? images[1] : images[0]);
  }, [images]);

  return (
    <div className="product-page">
      <Helmet>
        <title>YB's Closet - {props.data.title}</title>
        <meta
          name="description"
          content={`${props.data.title} available sizes ${props.data.items
            .map((item) => item.size)
            .join(", ")} and in conditions ${Array.from(
            new Set(
              props.data.items.map((item) => (item.isNew ? "New" : "Pre-owned"))
            )
          ).join("and ")} for ${
            props.data.brand.name
          } at $${minPrice} - $${maxPrice}`}
        />
      </Helmet>
      <div className="images">
        <div className="current-image">
          <img
            src={currentImage ? currentImage : assets.logo}
            alt={props.data.title}
          />
        </div>
        <div className="thumbnails">
          <button
            className="button left"
            onClick={() => {
              if (imageIndex > 0) {
                setImageIndex(imageIndex - 1);
                setCurrentImage(images[imageIndex - 1]);
              }
            }}
          >
            <img src={assets.left} alt="" />
          </button>
          <div className="images">
            {images.slice(imageIndex).map((image, index) => (
              <img
                key={index}
                className={currentImage === image ? "selected" : ""}
                src={image}
                alt={props.data.title + " thumbnail"}
                onClick={() => setCurrentImage(image)}
              />
            ))}
          </div>
          <button
            className="button right"
            onClick={() => {
              if (imageIndex < images.length - 1) {
                setImageIndex(imageIndex + 1);
                setCurrentImage(images[imageIndex + 1]);
              }
            }}
          >
            <img src={assets.right} alt="" />
          </button>
        </div>
      </div>
      <div className="data">
        <div className="brand">{props.data.brand.name}</div>
        <div className="name">{props.data.title}</div>
        <div className="description">{props.data.description}</div>
        <div className="conditions">
          <div className="title">Available Conditions:</div>
          {Array.from(
            new Set(
              props.data.items.map((item) => (item.isNew ? "New" : "Pre-owned"))
            )
          ).map((condition) => (
            <button
              key={condition}
              onClick={() => {
                if (currentCondition === (condition === "New" ? true : false))
                  return;
                if (currentProduct) {
                  if (
                    currentProduct.isNew !==
                    (condition === "New" ? true : false)
                  ) {
                    setCurrentSize(currentProduct.size);
                  }
                } else {
                  setCurrentSize(null);
                }
                setCurrentCondition(condition === "New" ? true : false);
              }}
              className={
                currentCondition === (condition === "New" ? true : false)
                  ? "selected"
                  : ""
              }
            >
              {condition}
            </button>
          ))}
        </div>
        <div className="sizes">
          <div className="title">Available Sizes:</div>
          {Array.from(new Set(props.data.items.map((item) => item.size)))
            .sort((a, b) => parseFloat(a) - parseFloat(b))
            .map((size) => (
              <Tooltip
                key={size}
                title={
                  props.data.items.findIndex(
                    (item) =>
                      item.size === size && item.isNew === currentCondition
                  ) !== -1
                    ? ""
                    : currentCondition
                    ? "Out of stock"
                    : "Select a condition first"
                }
              >
                <button
                  onClick={() => {
                    if (
                      currentSize === size ||
                      props.data.items.findIndex(
                        (item) =>
                          item.size === size && item.isNew === currentCondition
                      ) === -1
                    )
                      return;
                    if (currentProduct) {
                      const replacement = props.data.items.find(
                        (item) =>
                          item.size === size &&
                          item.isNew === currentProduct.isNew
                      );
                      if (replacement) {
                        setCurrentSize(size);
                      } else {
                        setCurrentSize(null);
                      }
                    } else {
                      setCurrentSize(size);
                    }
                  }}
                  className={
                    currentSize === size && currentProduct
                      ? "selected"
                      : props.data.items.findIndex(
                          (item) =>
                            item.size === size &&
                            item.isNew === currentCondition
                        ) !== -1
                      ? ""
                      : "disabled"
                  }
                >
                  {size}
                </button>
              </Tooltip>
            ))}
        </div>
        {currentProduct ? (
          currentProduct.isNew ? (
            <div className="quantity">
              {currentProduct.inventoryItems.filter(
                (item) => !props.cartItemIds.includes(item.id)
              ).length +
                "/" +
                currentProduct.inventoryItems.length}{" "}
              New items available
            </div>
          ) : (
            <div className="choices">
              <div className="title">Choose an item:</div>
              {currentProduct.inventoryItems.map((item, index) => (
                <button
                  key={item.id}
                  onClick={() => {
                    setCurrentItem(item);
                  }}
                  className={
                    currentItem && currentItem.id === item.id
                      ? "selected"
                      : props.cartItemIds.includes(item.id)
                      ? "in-cart"
                      : ""
                  }
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )
        ) : null}
        {currentItem ? (
          <div className="inventory-item-description">
            {currentItem.description}
          </div>
        ) : null}
        <div
          className="price"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.data.retailPrice ? (
            <div
              className="retail-price"
              style={{
                textDecoration: "line-through",
                marginRight: "10px",
              }}
            >
              ${props.data.retailPrice}
            </div>
          ) : null}
          {currentItem
            ? `$${currentItem.price}`
            : minPrice === maxPrice
            ? `$${minPrice}`
            : `$${minPrice} - $${maxPrice}`}
        </div>
        <div className="price-details">
          Shipping and taxes calculated at checkout
        </div>
        <div className="buttons">
          <button
            className={
              !currentItem || props.cartItemIds.includes(currentItem.id)
                ? "disabled"
                : ""
            }
            onClick={() => {
              if (currentItem && !props.cartItemIds.includes(currentItem.id)) {
                props.addToCart(currentItem.id);
                setCurrentItem(null);
              }
            }}
          >
            Add to Cart
          </button>
          <button
            className={
              currentSize === null || currentCondition === null
                ? "disabled"
                : ""
            }
            onClick={async () => {
              if (currentSize === null || currentCondition === null) return;
              const product = props.data.items.find(
                (item) =>
                  item.size === currentSize && item.isNew === currentCondition
              );
              if (product) {
                const item = currentItem
                  ? currentItem
                  : product.inventoryItems[product.inventoryItems.length - 1];
                if (item) {
                  window.location.href = "/request-purchase?id=" + item.id;
                }
              }
            }}
          >
            Request Purchase
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
