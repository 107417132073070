import React from "react";
import "./styles/content-page-container.css";
import assets from "../assets/";
import { Helmet } from "react-helmet-async";

const AboutUs: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          YB's Closet - Buy, Sell, and Explore the hottest kicks and clothes in
          Brooklyn, NYC.
        </title>
        <meta
          name="description"
          content="Discover a curated selection of sneakers, clothing, and accessories at YB's Closet in Brooklyn, NYC. Buy, sell, and explore the latest trends and timeless classics."
        />
      </Helmet>
      <div className="content-page-container about-us">
        <div className="content">
          <h1>About Us</h1>
          <p>
            Welcome to YB's Closet, your premier destination for top brands and
            coveted products in Brooklyn, NYC.
          </p>
          <p>
            At YB's Closet, we specialize in buying and selling a curated
            selection of sneakers, clothing, and accessories from a variety of
            brands. <br />
            Our mission is to provide our customers with access to the latest
            trends and timeless classics, all in one place.
          </p>
          <p>
            Whether you're looking to refresh your wardrobe with the hottest
            styles or sell your gently used items for cash, YB's Closet is here
            to help. <br />
            Our team is dedicated to offering a seamless shopping experience,
            ensuring that you find exactly what you're looking for every time
            you visit.
          </p>
          <p>
            Visit us at our Brooklyn location to explore our ever-changing
            inventory and discover the perfect pieces to elevate your style.
            <br />
            Thank you for choosing YB's Closet, where fashion meets convenience.
          </p>
        </div>
        <video controls className="ybs-video">
          <source src={assets.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default AboutUs;
