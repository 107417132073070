import React, { useEffect, useState } from "react";
import Table, { TableProps } from "antd/lib/table";
import Tabs from "antd/lib/tabs";
import { TabsProps } from "antd/lib/tabs";
import "./styles/orders.css";
import { message } from "antd";
import Popconfirm from "antd/lib/popconfirm";
import ConfigProvider from "antd/lib/config-provider";

interface Item {
  id: string;
  name: string;
  size: string;
  price: string;
  isNew: boolean;
  isSold: boolean;
  image: string;
}

interface OrderDataType {
  id: string;
  date: string;
  client: {
    id: string;
    name: string;
    email: string;
    instagram: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    zelle: string;
  };
  delivery: boolean;
  items: Item[];
}

interface approvedOrdersDataType {
  id: string;
  date: string;
  client: {
    id: string;
    name: string;
    email: string;
    instagram: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
  items: Item[];
  delivery: boolean;
  trackingNumber: string;
  delivered: boolean;
}

interface deliveredOrders {
  id: string;
  date: string;
  client: {
    id: string;
    name: string;
    email: string;
    instagram: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
  items: Item[];
  delivery: boolean;
  trackingNumber: string;
}

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<OrderDataType[]>([]);
  const [approvedOrders, setApprovedOrders] = useState<
    approvedOrdersDataType[]
  >([]);
  const [deliveredOrders, setDeliveredOrders] = useState<deliveredOrders[]>([]);

  function requestNewPurchaseRequests() {
    fetch("https://api.ybs-closet.com/cart/new", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setOrders(data);
      });
  }

  function requestApprovedOrders() {
    fetch("https://api.ybs-closet.com/cart/approved", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setApprovedOrders(data);
      });
  }

  function requestDeliveredOrders() {
    fetch("https://api.ybs-closet.com/cart/delivered", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setDeliveredOrders(data);
      });
  }

  useEffect(() => {
    requestNewPurchaseRequests();
    requestApprovedOrders();
    requestDeliveredOrders();
  }, []);

  const orderRequestsColumns: TableProps<OrderDataType>["columns"] = [
    {
      title: "Client Information",
      dataIndex: "client",
      key: "client",
      children: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Name",
          dataIndex: "client",
          key: "name",
          render: (client) => <p>{client.name}</p>,
        },
        {
          title: "Email",
          dataIndex: "client",
          key: "email",
          render: (client) => <p>{client.email}</p>,
        },
        {
          title: "Instagram",
          dataIndex: "client",
          key: "instagram",
          render: (client) => <p>{client.instagram}</p>,
        },
        {
          title: "Phone",
          dataIndex: "client",
          key: "phone",
          render: (client) => <p>{client.phone}</p>,
        },
        {
          title: "Address",
          dataIndex: "client",
          key: "address",
          render: (client) => <p>{client.address}</p>,
        },
        {
          title: "City",
          dataIndex: "client",
          key: "city",
          render: (client) => <p>{client.city}</p>,
        },
        {
          title: "State",
          dataIndex: "client",
          key: "state",
          render: (client) => <p>{client.state}</p>,
        },
        {
          title: "Zip Code",
          dataIndex: "client",
          key: "zipCode",
          render: (client) => <p>{client.zipCode}</p>,
        },
        {
          title: "Zelle",
          dataIndex: "client",
          key: "zelle",
          render: (client) => <p>{client.zelle}</p>,
        },
      ],
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      children: [
        {
          title: "Number of Items Available",
          dataIndex: "items",
          key: "number",
          render: (items: Item[]) => (
            <p>
              {items.filter((item) => !item.isSold).length} / {items.length}
            </p>
          ),
        },
        {
          title: "Total Price",
          dataIndex: "items",
          key: "price",
          render: (items: Item[], record, index) => {
            const availableItems = items.filter((item) => !item.isSold);
            const taxRate = 0.08875; // New York tax rate
            const shippingFee = 10; // Flat rate shipping fee
            const totalTaxed = availableItems.reduce(
              (acc, item) =>
                acc +
                (parseFloat(item.price) < 110 ? 0 : parseFloat(item.price)),
              0
            );
            const totalTaxes = totalTaxed * taxRate;

            const totalPriceBeforeTaxes = availableItems.reduce(
              (acc, item) => acc + parseFloat(item.price),
              0
            );

            const totalPriceAfterTaxes =
              totalPriceBeforeTaxes +
              totalTaxes +
              (record.delivery ? shippingFee : 0);
            return <p>{totalPriceAfterTaxes}$</p>;
          },
        },
      ],
    },
    {
      title: "Delivery",
      dataIndex: "delivery",
      key: "delivery",
      render: (delivery: boolean) => <p>{delivery ? "Yes" : "No"}</p>,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (id: string) => (
        <div className="order-actions">
          <Popconfirm
            title="Accept Order"
            description="Approve order payment?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              fetch(`https://api.ybs-closet.com/cart/approve/${id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
              }).then(() => {
                requestNewPurchaseRequests();
                requestApprovedOrders();
                message.success("Order Approved", 2);
              });
            }}
          >
            <button className="order-action">Accept</button>
          </Popconfirm>
          <Popconfirm
            title="Reject Order"
            description="Reject order payment?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              fetch(`https://api.ybs-closet.com/cart/reject/${id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
              }).then(() => {
                requestNewPurchaseRequests();
                message.success("Order Rejected", 2);
              });
            }}
          >
            <button className="order-action">Reject</button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const approvedRequestsColumns: TableProps<approvedOrdersDataType>["columns"] =
    [
      {
        title: "Client Information",
        dataIndex: "client",
        key: "client",
        children: [
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "Name",
            dataIndex: "client",
            key: "name",
            render: (client) => <p>{client.name}</p>,
          },
          {
            title: "Email",
            dataIndex: "client",
            key: "email",
            render: (client) => <p>{client.email}</p>,
          },
          {
            title: "Phone",
            dataIndex: "client",
            key: "phone",
            render: (client) => <p>{client.phone}</p>,
          },
          {
            title: "Instagram",
            dataIndex: "client",
            key: "instagram",
            render: (client) => <p>{client.instagram}</p>,
          },
          {
            title: "Address",
            dataIndex: "client",
            key: "address",
            render: (client) => <p>{client.address}</p>,
          },
          {
            title: "City",
            dataIndex: "client",
            key: "city",
            render: (client) => <p>{client.city}</p>,
          },
          {
            title: "State",
            dataIndex: "client",
            key: "state",
            render: (client) => <p>{client.state}</p>,
          },
          {
            title: "Zip Code",
            dataIndex: "client",
            key: "zipCode",
            render: (client) => <p>{client.zipCode}</p>,
          },
        ],
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        render: (id: string, item: approvedOrdersDataType, index: number) => (
          <div className="order-actions">
            {item.delivery ? (
              <>
                <input
                  type="text"
                  className="tracking-number-input"
                  placeholder="Tracking Number"
                  id={"tracking-number-input-" + index}
                />
                <Popconfirm
                  title="Approve Shipping"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    const trackingNumber = (
                      document.getElementById(
                        "tracking-number-input-" + index
                      ) as HTMLInputElement
                    ).value;
                    if (!trackingNumber || trackingNumber.length === 0) {
                      message.error("Please Enter Tracking Number", 2);
                      return;
                    }
                    fetch(
                      `https://api.ybs-closet.com/cart/deliver/${id}?trackingNumber=${trackingNumber}`,
                      {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        credentials: "include",
                      }
                    ).then((res) => {
                      if (res.ok) {
                        requestApprovedOrders();
                        message.success("Order Shipped", 2);
                      } else {
                        message.error("Error Shipping Order", 2);
                      }
                    });
                  }}
                >
                  <button
                    className="order-action"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Approve Shipping
                  </button>
                </Popconfirm>
              </>
            ) : (
              <Popconfirm
                title="Approve Pickup"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  fetch(`https://api.ybs-closet.com/cart/deliver/${id}`, {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    credentials: "include",
                  }).then((res) => {
                    if (res.ok) {
                      requestApprovedOrders();
                      message.success("Order Delivered", 2);
                    } else {
                      message.error("Error Delivering Order", 2);
                    }
                  });
                }}
              >
                <button className="order-action">Approve Pickup</button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ];

  const deliveredRequestsColumns: TableProps<deliveredOrders>["columns"] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Client Information",
      dataIndex: "client",
      key: "client",
      children: [
        {
          title: "Name",
          dataIndex: "client",
          key: "name",
          render: (client) => <p>{client.name}</p>,
        },
        {
          title: "Email",
          dataIndex: "client",
          key: "email",
          render: (client) => <p>{client.email}</p>,
        },
        {
          title: "Phone",
          dataIndex: "client",
          key: "phone",
          render: (client) => <p>{client.phone}</p>,
        },
        {
          title: "Instagram",
          dataIndex: "client",
          key: "instagram",
          render: (client) => <p>{client.instagram}</p>,
        },
        {
          title: "Address",
          dataIndex: "client",
          key: "address",
          render: (client) => <p>{client.address}</p>,
        },
        {
          title: "City",
          dataIndex: "client",
          key: "city",
          render: (client) => <p>{client.city}</p>,
        },
        {
          title: "State",
          dataIndex: "client",
          key: "state",
          render: (client) => <p>{client.state}</p>,
        },
        {
          title: "Zip Code",
          dataIndex: "client",
          key: "zipCode",
          render: (client) => <p>{client.zipCode}</p>,
        },
      ],
    },
    {
      title: "Shipped / Picked Up",
      dataIndex: "delivery",
      key: "delivery",
      render: (delivery: boolean) => (
        <p>{delivery ? "Shipped" : "Picked Up"}</p>
      ),
    },
  ];

  const tabsItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Order Requests",
      children: (
        <Table
          columns={orderRequestsColumns}
          dataSource={orders}
          rowKey="id"
          style={{
            height: "100%",
            width: "100%",
          }}
          bordered
          size="small"
          scroll={{ x: "scroll" }}
          expandable={{
            expandedRowRender: (record) => (
              <div className="item-details">
                <Table
                  columns={[
                    { title: "Name", dataIndex: "name", key: "name" },
                    { title: "Size", dataIndex: "size", key: "size" },
                    {
                      title: "Price",
                      dataIndex: "price",
                      key: "price",
                      render: (price) => `${price}$`,
                    },
                    {
                      title: "Condition",
                      dataIndex: "isNew",
                      key: "isNew",
                      render: (isNew) => (isNew ? "New" : "Used"),
                    },
                    {
                      title: "Availability",
                      dataIndex: "isSold",
                      key: "isSold",
                      render: (isSold) => (isSold ? "Sold " : "Available"),
                    },
                    {
                      title: "Image",
                      dataIndex: "image",
                      key: "image",
                      render: (image, item, index) => (
                        <img
                          src={image}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                          alt={
                            item.name +
                            (item.isNew ? " New" : " Used") +
                            " Item"
                          }
                        />
                      ),
                    },
                  ]}
                  dataSource={record.items}
                  rowKey="id"
                  size="small"
                  pagination={false}
                  style={{ width: "100%" }}
                  rowClassName={(record, index) =>
                    record.isSold ? "sold-item" : ""
                  }
                />
              </div>
            ),
            expandRowByClick: true,
          }}
        />
      ),
    },
    {
      key: "2",
      label: "Approved Orders",
      children: (
        <Table
          columns={approvedRequestsColumns}
          dataSource={approvedOrders}
          rowKey="id"
          style={{
            height: "100%",
            width: "100%",
          }}
          bordered
          size="small"
          scroll={{ x: "scroll" }}
          expandable={{
            expandedRowRender: (record) => (
              <div className="item-details">
                <Table
                  columns={[
                    { title: "Name", dataIndex: "name", key: "name" },
                    { title: "Size", dataIndex: "size", key: "size" },
                    {
                      title: "Condition",
                      dataIndex: "isNew",
                      key: "isNew",
                      render: (isNew) => (isNew ? "New" : "Used"),
                    },
                    {
                      title: "Image",
                      dataIndex: "image",
                      key: "image",
                      render: (image, item, index) => (
                        <img
                          src={image}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                          alt={
                            item.name +
                            (item.isNew ? " New" : " Used") +
                            " Item"
                          }
                        />
                      ),
                    },
                  ]}
                  dataSource={record.items}
                  rowKey="id"
                  size="small"
                  pagination={false}
                  style={{ width: "100%" }}
                />
              </div>
            ),
            expandRowByClick: true,
          }}
        />
      ),
    },
    {
      key: "3",
      label: "Delivered Orders",
      children: (
        <Table
          columns={deliveredRequestsColumns}
          dataSource={deliveredOrders}
          rowKey="id"
          style={{
            height: "100%",
            width: "100%",
          }}
          bordered
          size="small"
          scroll={{ x: "scroll" }}
          expandable={{
            expandedRowRender: (record) => (
              <div className="item-details">
                <Table
                  columns={[
                    { title: "Name", dataIndex: "name", key: "name" },
                    { title: "Size", dataIndex: "size", key: "size" },
                    {
                      title: "Condition",
                      dataIndex: "isNew",
                      key: "isNew",
                      render: (isNew) => (isNew ? "New" : "Used"),
                    },
                    {
                      title: "Image",
                      dataIndex: "image",
                      key: "image",
                      render: (image, item, index) => (
                        <img
                          src={image}
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                          alt={
                            item.name +
                            (item.isNew ? " New" : " Used") +
                            " Item"
                          }
                        />
                      ),
                    },
                  ]}
                  dataSource={record.items}
                  rowKey="id"
                  size="small"
                  pagination={false}
                  style={{ width: "100%" }}
                />
              </div>
            ),
            expandRowByClick: true,
          }}
        />
      ),
    },
  ];

  return (
    <div className="orders-dashboard">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ffffff",
            colorBgBase: "#1e1e1e",
            colorTextBase: "#4b4b4b",
            colorText: "#ffffff",
            colorTextPlaceholder: "#1e1e1e",
            colorTextHeading: "#ffffff",
            colorBorder: "#4b4b4b",
            colorBorderSecondary: "#4b4b4b",
            colorBgBlur: "#1e1e1e",
            colorBorderBg: "#1e1e1e",
            controlOutline: "rgba(125, 104, 0, 0.27)",
            fontFamily: `"Noto Sans", sans-serif`,
          },
        }}
      >
        <Tabs
          defaultActiveKey="1"
          items={tabsItems}
          size="large"
          style={{ width: "100%", height: "100%" }}
        />
      </ConfigProvider>
      <div className="order-details"></div>
    </div>
  );
};

export default Orders;
