import React, { useState } from "react";
import "./styles/header.css";
import assets from "../assets";

interface HeaderProps {
  title: string;
  links: { name: string; iconSVG: string; link: string }[];
  logOut?: () => void;
}

const Header: React.FC<HeaderProps> = ({ title, links, logOut }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="page-header">
        <div className="indicator">
          <picture>
            <source srcSet={assets.logo} media="(min-width: 767px)" />
            <source srcSet={assets.mobileLogo} media="(max-width: 767px)" />
            <img
              src={assets.logo}
              className="logo"
              alt="YB's Closet Logo"
              draggable={false}
              onClick={() => {
                window.location.href = "/";
              }}
            />
          </picture>
          <div className="page-title">{title}</div>
        </div>
        <div className="menu-btns">
          <div
            className="menu-btn"
            onClick={() => {
              if (logOut) logOut();
            }}
          >
            <img src={assets.logOut} alt="Log out" onClick={logOut} />
          </div>
          <div className="menu-btn" onClick={toggleMenu}>
            <img src={assets.hamburgerMenu} alt="Menu" />
          </div>
        </div>
      </div>
      <div className={`menu ${menuOpen ? "open" : ""}`}>
        {links.map((link) => (
          <a className="menu-item" href={link.link} key={link.name}>
            <img className="icon" src={link.iconSVG} alt={link.name} />
            <div className="name">{link.name}</div>
          </a>
        ))}
      </div>
    </>
  );
};

export default Header;
