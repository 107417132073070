import React from "react";
import "./styles/items-section.css";
import ProductCard from "./ProductCard";
import { ProductCardInterface } from "./ProductCard";
import Pagination from "antd/lib/pagination";
import { Helmet } from "react-helmet-async";

interface ItemsProps {
  title?: string;
  description?: string;
  items: ProductCardInterface[];
  totalItems: number;
  addToCart: (inventoryItemId: string) => void;
  updatePage: (page: number) => void;
  currentPage: number;
  cartItemIds: string[];
  pageSize?: number;
  changePageTitle?: boolean;
}

class Items extends React.Component<ItemsProps> {
  render() {
    const {
      title,
      description,
      items,
      totalItems,
      addToCart,
      updatePage,
      currentPage,
      pageSize = 20,
      changePageTitle = true,
    } = this.props;
    return (
      <>
        {changePageTitle && title ? (
          <Helmet>
            {<title>YB's Closet - {title}</title>}
            {description ? (
              <meta name="description" content={description} />
            ) : null}
          </Helmet>
        ) : null}
        {title && <div className="items-section-title">{title}</div>}
        <div className="items-section">
          {items.map((item, index) => (
            <ProductCard
              key={index}
              product={item}
              addToCart={addToCart}
              cartItemIds={this.props.cartItemIds}
            />
          ))}
        </div>
        {totalItems > 0 ? (
          <div className="full-width">
            <Pagination
              defaultCurrent={0}
              onChange={(page) => {
                updatePage(page);
              }}
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              style={{ marginBottom: "20px", marginTop: "20px" }}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default Items;
