import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "./styles/input.css";

interface InputProps {
  type: "text" | "file" | "number" | "email" | "password";
  accept?: string;
  label: string;
  value: string | number | undefined | string[];
  name: string;
  placeholder: string;
  error: boolean;
  disabled?: boolean;
  multiple?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({
  type,
  label,
  value,
  name,
  placeholder,
  error,
  disabled,
  accept,
  multiple,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string | number | undefined>(
    value as string | number | undefined
  );

  useEffect(() => {
    const inputElement = inputRef.current;
    if (!inputElement) return;

    const handleFocus = () => {
      inputElement.parentElement?.classList.add("focused");
    };

    const handleBlur = () => {
      inputElement.parentElement?.classList.remove("focused");
    };

    inputElement.addEventListener("focus", handleFocus);
    inputElement.addEventListener("blur", handleBlur);

    // Clean up the event listeners when the component unmounts
    return () => {
      inputElement.removeEventListener("focus", handleFocus);
      inputElement.removeEventListener("blur", handleBlur);
    };
  }, []);

  return (
    <div className="input-wrapper">
      <label htmlFor={label}>{label}</label>
      <div className="input">
        <input
          type={type}
          id={label}
          value={type === "file" ? undefined : inputValue}
          name={name}
          placeholder={placeholder}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e);
          }}
          disabled={disabled}
          accept={type === "file" ? accept : undefined}
          ref={inputRef}
          multiple={multiple}
        />
        {type === "file" && value ? (
          <img src={value as string} alt="preview" />
        ) : null}
      </div>
      {error && <p className="error">Input field can't be empty!</p>}
    </div>
  );
};

export default Input;
