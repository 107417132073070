import { FC, useEffect, useRef } from "react";
import "./styles/drop-down.css";
import Select from "antd/lib/select";

interface DropDownProps {
  label: string;
  options: {
    name: string | number;
    value: string | number;
  }[];
  value: string | number | undefined;
  placeholder: string;
  disabled?: boolean;
  onChange: (e: string) => void;
}

const DropDown: FC<DropDownProps> = ({
  label,
  options,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (!inputElement) return;

    const handleFocus = () => {
      inputElement.parentElement?.classList.add("focused");
    };

    const handleBlur = () => {
      inputElement.parentElement?.classList.remove("focused");
    };

    inputElement.addEventListener("focus", handleFocus);
    inputElement.addEventListener("blur", handleBlur);

    // Clean up the event listeners when the component unmounts
    return () => {
      inputElement.removeEventListener("focus", handleFocus);
      inputElement.removeEventListener("blur", handleBlur);
    };
  }, []);

  return (
    <div className="drop-down-wrapper">
      <label htmlFor={label}>{label}</label>
      <Select
        showSearch
        placeholder={placeholder}
        style={{ width: "100%" }}
        options={options.map((product) => ({
          label: product.name,
          value: product.value,
        }))}
        optionFilterProp="label"
        onChange={onChange}
        disabled={disabled}
        defaultValue={value?.toString() || undefined}
      />
    </div>
  );
};

export default DropDown;
