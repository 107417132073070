import React from "react";
import "./styles/footer.css";
import assets from "../assets";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-sections">
        <div className="footer-section customer-support">
          <div className="title">Customer Support</div>
          <button
            className="link"
            onClick={() => {
              navigate("/about-us");
              window.scrollTo(0, 0);
            }}
          >
            About Us
          </button>
          {/* <button className="link" href="/contact-us">
            Contact Us
          </button> */}
          <button
            className="link"
            onClick={() => {
              navigate("/faq");
              window.scrollTo(0, 0);
            }}
          >
            FAQs
          </button>
          <button
            className="link"
            onClick={() => {
              navigate("/privacy-policy");
              window.scrollTo(0, 0);
            }}
          >
            Privacy Policy
          </button>
          <button
            className="link"
            onClick={() => {
              navigate("/shipping-policy");
              window.scrollTo(0, 0);
            }}
          >
            Shipping Policy
          </button>
          <button
            className="link"
            onClick={() => {
              navigate("/returns-policy");
              window.scrollTo(0, 0);
            }}
          >
            Returns Policy
          </button>
        </div>
        <div className="footer-section contact-information">
          <div className="title">Contact Information</div>
          <div className="contact-item location">
            <div className="icon">
              <img src={assets.location} alt="Location, Address" />
            </div>
            <div>2539 Ocean Avenue, Brooklyn Ny 11229</div>
          </div>
          <div className="contact-item opening-hours">
            <div className="icon">
              <img src={assets.openingHours} alt="Opening Hours" />
            </div>
            <div>2:00 - 7:00 PM, Closed on Mondays</div>
          </div>
          <div className="contact-item email">
            <div className="icon">
              <img src={assets.email} alt="Email Address" />
            </div>
            <div>support@ybs-closet.com</div>
          </div>
          <div className="contact-item instagram">
            <div className="icon">
              <img src={assets.instagram} alt="Instagram" />
            </div>
            <a href="https://www.instagram.com/ybsclosetnycc">@ybsclosetnycc</a>
          </div>
        </div>
      </div>
      <div className="credits">
        <div>© 2021 YB's Closet</div>
        <div>
          Developed by{" "}
          <a
            href="https://www.na-systems.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            NA Systems
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
