import React, { useState, useEffect } from "react";
import "./styles/navigation-bar.css";
import assets from "../assets";
import Input from "antd/lib/input";
import AutoComplete from "antd/lib/auto-complete";
import FloatButton from "antd/lib/float-button";
import ConfigProvider from "antd/lib/config-provider";
import Badge from "antd/lib/badge";
import { useNavigate } from "react-router-dom";
import { ProductCardInterface } from "./ProductCard";
import { productPageInterface } from "./ProductPage";
import { SearchOutlined } from "@ant-design/icons";

type categoriesType = {
  id: string;
  name: string;
  subItems: categoriesType[];
};

type brandsType = {
  id: string;
  logoURL: string;
  name: string;
};

const NavigationBar: React.FC<{
  currentPage: ProductCardInterface[] | productPageInterface | null;
  searchResults: ProductCardInterface[];
  filtersCount?: number;
  dealsCount?: number;
  cartSize?: number;
  onOpenCart: () => void;
  onOpenFilter: () => void;
  onSearch: (searchText: string) => void;
  onSearchBtnClick: () => void;
  updateFilters: (filters: {
    id?: string;
    page?: string;
    isNew?: boolean;
    brands?: string;
    categories?: string;
    sizes?: string;
    deals?: boolean;
  }) => void;
  categories?: categoriesType[];
  brands?: brandsType[];
}> = (props) => {
  const onOpenCart = props.onOpenCart;
  const onOpenFilter = props.onOpenFilter;
  const [isBrandsDropdownVisible, setIsBrandsDropdownVisible] = useState(false);
  const [isCategoriesDropdownVisible, setIsCategoriesDropdownVisible] =
    useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  let prevScrollPos = window.scrollY;

  const handleScroll = () => {
    let currentScrollPos = window.scrollY;
    if (currentScrollPos < prevScrollPos || currentScrollPos <= 10) {
      // User is scrolling up
      document
        .getElementsByClassName("navigation-bar")[0]
        .setAttribute("style", "top: 0px");
    } else {
      // User is scrolling down
      document
        .getElementsByClassName("navigation-bar")[0]
        .setAttribute("style", "top: -250px");
    }
    prevScrollPos = currentScrollPos;
  };

  useEffect(
    () => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isMenuOpen]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#4b4b4b",
            colorBgBase: "#ffffff",
            colorTextBase: "#4b4b4b",
            colorText: "#4b4b4b",
            colorTextPlaceholder: "#4b4b4b",
            colorBorder: "#4b4b4b",
            colorBorderSecondary: "#4b4b4b",
            colorBgBlur: "#ffffff",
            colorBorderBg: "#ffffff",
            controlOutline: "rgba(125, 104, 0, 0.27)",
          },
        }}
      >
        <FloatButton
          icon={
            <img
              style={{ height: "100%", width: "100%" }}
              src={assets.shoppingCart}
              alt="Shopping Cart"
            />
          }
          type="default"
          style={{
            bottom: isMobile ? 30 : undefined,
            top: !isMobile ? 30 : undefined,
            right: 20,
            width: 50,
            height: 50,
          }}
          onClick={() => onOpenCart()}
          badge={{
            count: props.cartSize,
            style: {
              backgroundColor: "var(--quinary-color)",
              color: "var(--tertiary-color)",
            },
          }}
          // tooltip="Shopping Cart"
        />
      </ConfigProvider>
      <div className="navigation-bar">
        <img
          src={assets.logo}
          className="logo"
          alt="Logo Text"
          draggable={false}
          onClick={() => {
            props.updateFilters({});
            navigate("/");
          }}
        />
        <div className={"navigation-links" + (isMenuOpen ? " active" : "")}>
          <div className="close-btn">
            <img
              src={assets.close}
              alt="Close"
              draggable={false}
              onClick={() => setIsMenuOpen(false)}
            />
          </div>
          <div className="links">
            <button
              className="link"
              onClick={() => {
                props.updateFilters({});
                navigate("/");
                setIsMenuOpen(false);
              }}
            >
              Home
            </button>
            {props.dealsCount && props.dealsCount > 0 ? (
              <button
                className="link"
                onClick={() => {
                  props.updateFilters({ deals: true });
                  navigate("/products?deals=true");
                  setIsMenuOpen(false);
                }}
              >
                Deals
              </button>
            ) : null}
            {props.categories?.map((category) => (
              <div
                onMouseEnter={() => {
                  setIsCategoriesDropdownVisible(category.id);
                  setIsBrandsDropdownVisible(false);
                }}
                onMouseLeave={() => {
                  setIsCategoriesDropdownVisible(null);
                  setIsBrandsDropdownVisible(false);
                }}
                key={category.id}
                className="navigation-item"
              >
                <button
                  className="link"
                  onClick={() => {
                    if (isMobile) {
                      if (
                        isCategoriesDropdownVisible === category.id ||
                        category.subItems.length === 0
                      ) {
                        navigate(`/products?categories=${category.id}`);
                        setIsMenuOpen(false);
                      }
                    } else {
                      navigate(`/products?categories=${category.id}`);
                      setIsMenuOpen(false);
                    }
                  }}
                >
                  {category.name}
                </button>
                {category.subItems && category.subItems.length > 0 && (
                  <div
                    className={
                      "navigation-dropdown-items" +
                      (isCategoriesDropdownVisible === category.id
                        ? " active"
                        : "")
                    }
                  >
                    {category.subItems.map((subItem) => (
                      <button
                        className="link"
                        key={subItem.id}
                        onClick={() => {
                          navigate(`/products?categories=${subItem.id}`);
                          setIsMenuOpen(false);
                        }}
                      >
                        {subItem.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div
              className="navigation-item"
              onMouseEnter={() => {
                setIsBrandsDropdownVisible(true);
                setIsCategoriesDropdownVisible(null);
              }}
              onMouseLeave={() => {
                setIsCategoriesDropdownVisible(null);
                setIsBrandsDropdownVisible(false);
              }}
            >
              <button
                className="link"
                onClick={() => {
                  setIsBrandsDropdownVisible(!isBrandsDropdownVisible);
                }}
              >
                Brands
              </button>
              {(props.brands ? props.brands.length : 0) > 0 && (
                <div
                  className={
                    "navigation-dropdown-items" +
                    (isBrandsDropdownVisible ? " active" : "")
                  }
                >
                  {props.brands?.map((brand) => (
                    <button
                      className="link"
                      key={brand.id}
                      onClick={() => {
                        navigate(`/products?brands=${brand.id}`);
                        setIsMenuOpen(false);
                      }}
                    >
                      {brand.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <button
              className="link"
              onClick={() => {
                navigate("/about-us");
                setIsMenuOpen(false);
              }}
            >
              About Us
            </button>
          </div>
        </div>
        <div className="full-width reversed search-bar-container">
          <AutoComplete
            className="search-bar"
            showSearch={true}
            placement="bottomLeft"
            placeholder="Search"
            value={inputValue} // control the input value
            options={
              props.searchResults
                ? props.searchResults.map((item) => ({
                    value: item.id,
                    label: (item.brand.name + " " + item.title).toUpperCase(),
                  }))
                : []
            }
            onSelect={(value, option) => {
              if (value == null) return;
              // navigate to root / products page with the selected product id
              navigate(`/products?id=${value}`);
              setInputValue(option.label); // set the input value to the label of the selected option
            }}
            onSearch={(value) => {
              setInputValue(value); // update the input value when the user types
              props.onSearch(value);
            }}
          >
            <Input.Search
              className="search-bar"
              type="text"
              enterButton={<SearchOutlined onClick={props.onSearchBtnClick} />}
              onPressEnter={props.onSearchBtnClick}
            />
          </AutoComplete>
          <Badge
            count={props.filtersCount}
            style={{
              backgroundColor: "var(--quinary-color)",
              color: "var(--tertiary-color)",
            }}
          >
            <div className="filter-button" onClick={onOpenFilter}>
              <div className="title">Filter</div>
              <img src={assets.filter} alt="Filter" draggable={false} />
            </div>
          </Badge>
        </div>
        <img
          className="menu-btn"
          src={assets.hamburgerMenu}
          alt="Menu"
          draggable={false}
          onClick={() => {
            setIsMenuOpen(true);
          }}
        />
      </div>
    </>
  );
};

export default NavigationBar;
