import React, { useState, useEffect } from "react";
import "./styles/form.css";
import { itemType } from "./DashboardList";
import { dashboardRequest, dataType } from "./Dashboard";
import assets from "../assets";
import Input from "./Input";
import TextArea from "./TextArea";
import DropDown from "./DropDown";
import { Switch, Upload, Modal, UploadFile, UploadProps } from "antd";

export type FormProps = {
  data: dataType;
  currentForm: dashboardRequest;
  updateForm: (form: dashboardRequest) => void;
  closeForm: () => void;
  confirmForm: (form: dashboardRequest) => Promise<boolean>;
};

type FormState = {
  currentForm: dashboardRequest;
  message: {
    type: "Error" | "Warning" | "Success";
    text: string;
    buttons: {
      cancel?: {
        text: string;
        action: () => void;
      } | null;
      ok: {
        text: string;
        action: () => void;
      };
    };
  } | null;
};

const Form: React.FC<FormProps> = (props) => {
  const [currentForm, setCurrentForm] = useState<dashboardRequest>(
    props.currentForm
  );
  const [message, setMessage] = useState<FormState["message"]>(null);

  useEffect(() => {
    if (props.currentForm !== currentForm) {
      setCurrentForm(props.currentForm);
    }
  }, [props.currentForm, currentForm]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    props.updateForm({ ...props.currentForm, name });
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const description = e.target.value;
    props.updateForm({ ...props.currentForm, description });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      props.updateForm({ ...props.currentForm, image: "" });
      return;
    }
    const image = URL.createObjectURL(files[0]);

    props.updateForm({ ...props.currentForm, image });
  };

  const handleOriginalPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const originalPrice = e.target.valueAsNumber;
    props.updateForm({ ...props.currentForm, originalPrice });
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>(
    (currentForm.images?.map((image) => ({
      uid: image.uid,
      name: image.uid,
      status: "done",
      url: image.url,
    })) as UploadFile[]) || []
  );

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({
    file,
    fileList: newFileList,
  }: {
    file: UploadFile;
    fileList: UploadFile[];
  }) => {
    if (file.status === "done") {
      newFileList = newFileList.map((item) => {
        if (item.uid === file.uid) {
          return {
            uid: file.response.uid,
            url: file.response.url,
            name: file.response.uid,
          };
        }
        return item;
      });
    }
    setFileList(newFileList);
    props.updateForm({
      ...props.currentForm,
      images: newFileList.map((file) => {
        return {
          uid: file.uid as string,
          url: file.url as string,
        };
      }),
    });
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const makeFormElements = (type: itemType) => {
    switch (type) {
      case itemType.Category:
        return (
          <div className="form-elements">
            {props.data.categories && (
              <DropDown
                label="Parent Category"
                options={props.data.categories}
                onChange={(e) => {
                  props.updateForm({
                    ...props.currentForm,
                    parentId: e,
                  });
                }}
                placeholder="None"
                value={currentForm.parentId}
              />
            )}
            <Input
              type="text"
              name="name"
              value={currentForm.name}
              label="Name"
              placeholder="Enter the name of the category"
              error={false}
              onChange={handleNameChange}
            />
            <TextArea
              type="text"
              name="description"
              value={currentForm.description}
              label="Description"
              placeholder="Enter the description of the category"
              error={false}
              onChange={handleDescriptionChange}
            />
          </div>
        );
      case itemType.Brand:
        return (
          <div className="form-elements">
            {props.data.brands && (
              <DropDown
                label="Parent Category"
                options={props.data.brands}
                onChange={(e) => {
                  props.updateForm({
                    ...props.currentForm,
                    parentId: e,
                  });
                }}
                placeholder="None"
                value={currentForm.parentId}
              />
            )}
            <Input
              type="text"
              name="name"
              value={currentForm.name}
              label="Name"
              placeholder="Enter the name of the brand"
              error={false}
              onChange={handleNameChange}
            />
            <TextArea
              type="text"
              name="description"
              value={currentForm.description}
              label="Description"
              placeholder="Enter the description of the brand"
              error={false}
              onChange={handleDescriptionChange}
            />
            <Input
              type="file"
              name="image"
              accept="image/*"
              value={currentForm.image}
              error={false}
              label="Image"
              onChange={handleImageChange}
              placeholder="Upload the logo of the brand"
            />
          </div>
        );
      case itemType.Product:
        return (
          <div className="form-elements">
            <Input
              type="text"
              name="name"
              value={currentForm.name}
              label="Name"
              placeholder="Enter the name of the product"
              error={false}
              onChange={handleNameChange}
            />
            {props.data.brands && (
              <DropDown
                label="Brand"
                options={props.data.brands}
                onChange={(e) => {
                  props.updateForm({
                    ...props.currentForm,
                    brandId: e,
                  });
                }}
                placeholder="None"
                value={currentForm.brandId}
              />
            )}
            {props.data.categories && (
              <DropDown
                label="Category"
                options={props.data.categories}
                onChange={(e) => {
                  props.updateForm({
                    ...props.currentForm,
                    categoryId: e,
                  });
                }}
                placeholder="None"
                value={currentForm.categoryId}
              />
            )}
            <TextArea
              type="text"
              name="description"
              value={currentForm.description}
              label="Description"
              placeholder="Enter the description of the product"
              error={false}
              onChange={handleDescriptionChange}
            />
            <Input
              type="number"
              name="originalPrice"
              value={currentForm.originalPrice}
              error={false}
              label="Retail Price"
              onChange={handleOriginalPriceChange}
              placeholder="Retail Price of the product"
            />
            <Input
              type="file"
              name="image"
              accept="image/*"
              value={currentForm.image}
              error={false}
              label="Product Image"
              onChange={handleImageChange}
              placeholder="Upload the image of the product with transparent / white background"
            />
          </div>
        );
      case itemType.Inventory:
        return (
          <div className="form-elements">
            {props.data.products && (
              <DropDown
                label="Product"
                options={props.data.products}
                onChange={(e) => {
                  props.updateForm({
                    ...props.currentForm,
                    productId: e,
                  });
                }}
                placeholder="None"
                value={currentForm.productId}
              />
            )}
            <TextArea
              type="text"
              name="description"
              value={currentForm.description}
              label="Description"
              placeholder="Enter the description of the product"
              error={false}
              onChange={handleDescriptionChange}
            />
            <Input
              type="text"
              name="size"
              error={false}
              label="Size"
              placeholder="Enter the size of the product"
              value={props.currentForm.size}
              onChange={(e) => {
                props.updateForm({
                  ...props.currentForm,
                  size: e.target.value,
                });
              }}
            />
            <Input
              type="number"
              name="buyingPrice"
              error={false}
              label="Buying Price"
              placeholder="Enter the buying price of the item"
              value={currentForm.buyPrice}
              onChange={(e) => {
                props.updateForm({
                  ...props.currentForm,
                  buyPrice: e.target.valueAsNumber,
                });
              }}
            />
            <Input
              type="number"
              name="sellingPrice"
              error={false}
              label="Sell Price"
              placeholder="Enter the sell price of the item"
              value={currentForm.sellPrice}
              onChange={(e) => {
                props.updateForm({
                  ...props.currentForm,
                  sellPrice: e.target.valueAsNumber,
                });
              }}
            />
            <div className="switches">
              <Switch
                checkedChildren="New"
                unCheckedChildren="Pre-owned"
                defaultChecked={currentForm.isNew}
                onChange={(checked) => {
                  props.updateForm({
                    ...props.currentForm,
                    isNew: checked,
                  });
                }}
                title="Item's Condition (More details in the description)"
                value={currentForm.isNew}
              />
              <Switch
                checkedChildren="Sold"
                unCheckedChildren="Available"
                defaultChecked={currentForm.isSold}
                onChange={(sold) => {
                  props.updateForm({
                    ...props.currentForm,
                    isSold: sold,
                  });
                }}
                title="Item's Availability"
                value={currentForm.isSold}
                style={{ marginLeft: "10px" }}
              />
              <Switch
                checkedChildren="Deal"
                unCheckedChildren="Regular Price"
                defaultChecked={currentForm.deal}
                onChange={(deal) => {
                  props.updateForm({
                    ...props.currentForm,
                    deal: deal,
                  });
                }}
                title="Item's Availability"
                value={currentForm.deal}
                style={{ marginLeft: "10px" }}
              />
            </div>
            {props.currentForm.isNew && !props.currentForm.id ? (
              <Input
                type="number"
                name="quantity"
                error={false}
                label="Quantity"
                placeholder="Enter the quantity of the item"
                value={currentForm.quantity || 1}
                onChange={(e) => {
                  props.updateForm({
                    ...props.currentForm,
                    quantity: e.target.valueAsNumber,
                  });
                }}
              />
            ) : null}
            <div className="upload-files">
              <Upload
                action={"https://api.ybs-closet.com/images"}
                onRemove={async (file) => {
                  if (file.uid) {
                    const removed = await fetch(
                      `https://api.ybs-closet.com/images/${file.uid}`,
                      {
                        method: "DELETE",
                        credentials: "include",
                      }
                    ).then((res) => res.status === 200);
                    if (!removed) return;
                  }
                  const index = fileList.indexOf(file);
                  const newFileList = fileList.slice();
                  newFileList.splice(index, 1);
                  setFileList(newFileList);
                  props.updateForm({
                    ...props.currentForm,
                    images: newFileList.map((file) => {
                      return {
                        uid: file.uid as string,
                        url: file.url as string,
                      };
                    }),
                  });
                }}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                name="image"
                withCredentials
              >
                {fileList.length >= 5 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const formElements = makeFormElements(currentForm.type);

  return (
    <div className="form-container">
      <div className="form">
        <div className="header">
          <div className="title">
            {currentForm.id ? "Edit" : "New"} {currentForm.type}
          </div>
          <div className="buttons">
            <img
              alt="Close Form"
              src={assets.close}
              className="close"
              onClick={() => {
                setMessage({
                  type: "Warning",
                  text: `Are you sure you want to close the form?
                            All unsaved changes will be lost.`,
                  buttons: {
                    cancel: {
                      text: "No",
                      action: () => {
                        setMessage(null);
                      },
                    },
                    ok: {
                      text: "Yes",
                      action: () => {
                        setMessage(null);
                        props.closeForm();
                      },
                    },
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="elements">
          {formElements}
          <div className="submit">
            <button
              onClick={async () => {
                let result = false;
                switch (currentForm.type) {
                  case itemType.Category:
                    if (!currentForm.name)
                      return setMessage({
                        type: "Error",
                        text: "Name is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    break;
                  case itemType.Brand:
                    if (!currentForm.name)
                      return setMessage({
                        type: "Error",
                        text: "Name is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    // if (!currentForm.image)
                    //   return setMessage({
                    //     type: "Error",
                    //     text: "Image is a required field!",
                    //     buttons: {
                    //       ok: {
                    //         text: "Ok",
                    //         action: () => {
                    //           setMessage(null);
                    //         },
                    //       },
                    //     },
                    //   });
                    break;
                  case itemType.Product:
                    if (!currentForm.name)
                      return setMessage({
                        type: "Error",
                        text: "Name is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    if (!currentForm.brandId)
                      return setMessage({
                        type: "Error",
                        text: "Brand is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    if (!currentForm.categoryId)
                      return setMessage({
                        type: "Error",
                        text: "Category is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    if (!currentForm.image)
                      return setMessage({
                        type: "Error",
                        text: "Image is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    break;
                  case itemType.Inventory:
                    if (!currentForm.productId)
                      return setMessage({
                        type: "Error",
                        text: "Product is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    if (!currentForm.size)
                      return setMessage({
                        type: "Error",
                        text: "Size is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    if (!currentForm.sellPrice)
                      return setMessage({
                        type: "Error",
                        text: "Selling price is a required field!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    if (!currentForm.images || currentForm.images.length === 0)
                      return setMessage({
                        type: "Error",
                        text: "At least one image is required!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });
                    if (
                      fileList.findIndex((f) => f.status === "uploading") >= 0
                    )
                      return setMessage({
                        type: "Error",
                        text: "Please wait for the images to upload!",
                        buttons: {
                          ok: {
                            text: "Ok",
                            action: () => {
                              setMessage(null);
                            },
                          },
                        },
                      });

                    break;
                  default:
                    break;
                }
                result = await props.confirmForm(currentForm);
                if (result) {
                  setMessage({
                    type: "Success",
                    text: "Form submitted successfully!",
                    buttons: {
                      ok: {
                        text: "Ok",
                        action: () => {
                          setMessage(null);
                          props.closeForm();
                        },
                      },
                    },
                  });
                } else {
                  setMessage({
                    type: "Error",
                    text: "Form submission failed!",
                    buttons: {
                      ok: {
                        text: "Ok",
                        action: () => {
                          setMessage(null);
                        },
                      },
                    },
                  });
                }
              }}
            >
              {currentForm.id ? "Update" : "Create"} {currentForm.type}
            </button>
          </div>
        </div>
        {message && (
          <div className={"pop-up " + message.type}>
            <div className="pop-up-header">
              <span>{message.type}</span>
              <img
                className="button"
                src={assets.close}
                alt="Close Message"
                onClick={() => {
                  setMessage(null);
                }}
              />
            </div>
            <div className="pop-up-body">
              {message.text.split("\n").map((text, index) => {
                return <p key={index}>{text}</p>;
              })}
            </div>
            <div className="buttons">
              {message.buttons.cancel && (
                <button
                  className="cancel"
                  onClick={message.buttons.cancel.action}
                >
                  {message.buttons.cancel.text}
                </button>
              )}
              {message.buttons.ok && (
                <button className="ok" onClick={message.buttons.ok.action}>
                  {message.buttons.ok.text}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
