import React from "react";
import "./styles/content-page-container.css";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>YB's Closet - Privacy Policy</title>
        <meta
          name="description"
          content="Privacy Policy for YB's Closet, a premier destination for top brands in Brooklyn, NYC. Learn how we collect, use, and safeguard your information when you visit our store or website."
        />
      </Helmet>
      <div className="content-page-container">
        <p>
          <strong>Effective Date: 03/06/2024</strong>
        </p>

        <h1>Privacy Policy</h1>

        <p>
          We are committed to protecting your privacy.
          <br />
          This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you visit our store or website (the
          "Services").
          <br />
          Please read this Privacy Policy carefully.
          <br />
          If you do not agree with the terms of this Privacy Policy, please do
          not access the Services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect personal information that you provide to us directly,
          such as:
        </p>
        <p>- Name</p>
        <p>- Email address</p>
        <p>- Phone number</p>
        <p>- Shipping address</p>
        <p>
          We may also collect information automatically when you use our
          Services, including:
        </p>
        <p>- IP address</p>
        <p>- Browser type</p>
        <p>- Device information</p>
        <p>- Usage data (e.g., pages visited, time spent on pages)</p>

        <h2>2. How We Use Your Information</h2>
        <p>
          We use the information we collect for various purposes, including:
        </p>
        <p>- To process transactions and manage your orders</p>
        <p>- To provide and improve our Services</p>
        <p>
          - To communicate with you, including sending you updates and
          promotional materials
        </p>
        <p>- To personalize your experience with our Services</p>
        <p>- To comply with legal obligations and protect our rights</p>

        <h2>3. Sharing Your Information</h2>
        <p>We may share your information with:</p>
        <p>
          - Third-party service providers who perform services on our behalf
          (e.g., shipping companies)
        </p>
        <p>
          - Law enforcement or other governmental authorities when required by
          law
        </p>
        <p>- Other parties with your consent or at your direction</p>

        <h2>4. Data Security</h2>
        <p>
          We implement reasonable security measures to protect your information
          from unauthorized access, use, or disclosure. However, no data
          transmission over the internet or storage system can be guaranteed to
          be 100% secure.
        </p>

        <h2>5. Your Rights</h2>
        <p>
          Depending on your location, you may have certain rights regarding your
          personal information, including the right to access, correct, or
          delete your information. To exercise these rights, please contact us
          using the information provided below.
        </p>

        <h2>6. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
          Your continued use of our Services after such changes will constitute
          your acknowledgment of the modified Privacy Policy.
        </p>

        <h2>7. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>
        <p>
          YB's Closet
          <br />
          2539 Ocean Avenue
          <br />
          Brooklyn, NY 11229
          <br />
          Email:{" "}
          <a href="mailto:support@ybs-closet.com">support@ybs-closet.com</a>
          <br />
          Instagram:{" "}
          <a href="https://www.instagram.com/ybsclosetnycc">@ybsclosetnycc</a>
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
