import React from "react";
import Popconfirm from "antd/lib/popconfirm";
import icons from "../assets";
import "./styles/dashboard-list.css";
import Input from "antd/lib/input";
import { Switch } from "antd";

export enum itemType {
  Category = "Category",
  Brand = "Brand",
  Product = "Product",
  Inventory = "Inventory Item",
}

export type itemsType = {
  id?: string;
  type: itemType;
  name: string;
  items?: itemsType[];
  image?: string;
  images?: string[];
  isSold?: boolean;
};

export type propsType = {
  type: itemType;
  name: string;
  items: itemsType[];
  addItem: (type: itemType) => void;
  addSubItem: (type: itemType, id: string) => void;
  editItem: (type: itemType, id: string) => void;
  duplicateItem: (type: itemType, id: string) => void;
  deleteItem: (type: itemType, id: string) => void;
};

type stateType = {
  searchValue: null | string;
  selectedItemId: null | string;
  switch: boolean;
};

export class DashboardList extends React.Component<propsType, stateType> {
  constructor(props: propsType) {
    super(props);
    this.state = {
      searchValue: null,
      selectedItemId: null,
      switch: false,
    };
  }

  searchItems = (item: itemsType): boolean => {
    if (!this.state.searchValue) return true;
    let contained = item.name
      .toLowerCase()
      .includes(this.state.searchValue.toLowerCase());
    if (contained) return true;
    if (item.items) {
      return item.items.some(
        (subItem) =>
          this.state.searchValue &&
          (subItem.name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
            this.searchItems(subItem))
      );
    }
    return false; // Add this line to handle the case when none of the conditions are met.
  };

  generateItems(items: itemsType[], firstLevel = true) {
    if (!items) return null;
    return items
      .filter((item) => this.searchItems(item))
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      .map((item) => {
        return (
          <React.Fragment key={"list-item-" + item.id}>
            <div key={"list-item-item-" + item.id} className="list-item">
              {!firstLevel ||
              !item.items ||
              item.items.length === 0 ||
              item.items[0].type === itemType.Category ||
              item.items[0].type === itemType.Brand ? (
                <>
                  <div
                    className={
                      "list-item" +
                      (this.state.selectedItemId === item.id ? " selected" : "")
                    }
                    onDoubleClick={(e) => {
                      this.setState({ selectedItemId: item.id || null });
                      e.stopPropagation();
                    }}
                    key={"list-item-" + item.id}
                  >
                    <div className="name">{item.name}</div>
                    <div className="buttons">
                      {(this.props.type === itemType.Brand ||
                        this.props.type === itemType.Category) && (
                        <Popconfirm
                          title={"Add Sub  " + this.props.type}
                          description={
                            "Would you like to add a sub " +
                            this.props.type.toLowerCase() +
                            "?"
                          }
                          onConfirm={() => {
                            this.props.addSubItem(
                              this.props.type,
                              item.id || ""
                            );
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <img
                            className="action-btn add"
                            alt="Add"
                            src={icons.addItem}
                          />
                        </Popconfirm>
                      )}
                      {item.type === itemType.Inventory ? (
                        <Popconfirm
                          title={"Duplicate " + this.props.type}
                          description={
                            "Would you like to duplicate this " +
                            this.props.type.toLowerCase() +
                            "?"
                          }
                          onConfirm={() => {
                            this.props.duplicateItem(
                              this.props.type,
                              item.id || ""
                            );
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <img
                            className="action-btn add"
                            alt="Edit"
                            src={icons.addItem}
                          />
                        </Popconfirm>
                      ) : null}
                      <Popconfirm
                        title={"Edit  " + this.props.type}
                        description={
                          "Would you like to edit this " +
                          this.props.type.toLowerCase() +
                          "?"
                        }
                        onConfirm={() => {
                          this.props.editItem(this.props.type, item.id || "");
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <img
                          className="action-btn edit"
                          alt="Edit"
                          src={icons.editItem}
                        />
                      </Popconfirm>
                      <Popconfirm
                        title={"Delete " + this.props.type}
                        description={
                          "Are you sure to delete this " +
                          this.props.type.toLowerCase() +
                          "?"
                        }
                        onConfirm={() => {
                          this.props.deleteItem(this.props.type, item.id || "");
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <img
                          className="action-btn remove"
                          alt="Remove"
                          src={icons.remove}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                  {this.state.selectedItemId === item.id &&
                  item.image !== undefined ? (
                    <div className="image">
                      <img src={item.image} alt="Item" />
                    </div>
                  ) : null}
                </>
              ) : null}
              {item.items && (
                <div className="sub-items" key={"sub-items-" + item.id}>
                  {item.type === itemType.Inventory
                    ? this.generateItems(
                        item.items.filter((item) => {
                          return item.isSold === this.state.switch;
                        }),
                        false
                      )
                    : this.generateItems(item.items, false)}
                </div>
              )}
            </div>
          </React.Fragment>
        );
      });
  }

  render() {
    return (
      <div className="dashboard-list" key={"dashboard-list-" + this.props.type}>
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
            width: "calc(100% - 20px)",
          }}
        >
          <div className="title">{this.props.name}</div>
          {this.props.type === itemType.Inventory ? (
            <Switch
              checkedChildren="Sold Items"
              unCheckedChildren="Available for Sale"
              checked={this.state.switch}
              onChange={(checked) => {
                this.setState({ switch: checked });
              }}
            />
          ) : null}
        </div>
        <div className="search-items">
          <Input.Search
            className="search-bar"
            type="text"
            placeholder="Search"
            style={{
              width: "97%",
            }}
            onChange={(value) => {
              this.setState({ searchValue: value.target.value });
            }}
            size="large"
          />
        </div>
        <div className="list-container">
          {this.props.type === itemType.Inventory
            ? this.generateItems(
                this.props.items.filter(
                  (item) => item.isSold === this.state.switch
                )
              )
            : this.generateItems(this.props.items)}
        </div>
        <div className="buttons">
          <div
            className="add-button"
            onClick={() => this.props.addItem(this.props.type)}
          >
            <img src={icons.addItem} alt="Add Item" />
          </div>
        </div>
      </div>
    );
  }
}
