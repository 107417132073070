import React, { useEffect, useState } from "react";
import { ProductCardInterface } from "./ProductCard";
import Items from "./ItemsSection";
import { Helmet } from "react-helmet";
import Spin from "antd/lib/spin";
import { useNavigate } from "react-router-dom";

const Home: React.FC<{
  categories: {
    name: string;
    id: string;
  }[];
  addToCart: (inventoryItemId: string) => void;
  cartItemIds: string[];
  filters: {
    id?: string;
    page?: string;
    isNew?: boolean;
    brands?: string;
    categories?: string;
    sizes?: string;
  };
}> = (props) => {
  const pageSize = 10;

  const [categoriesData, setCategoriesData] = useState<{
    [key: string]: {
      name: string;
      totalItems: number;
      currentPage: number;
      items: ProductCardInterface[];
    };
  }>({});

  const [dealsData, setDealsData] = useState<{
    totalItems: number;
    currentPage: number;
    items: ProductCardInterface[];
  }>();

  const navigate = useNavigate();

  useEffect(
    () => {
      const filtersQuery = Object.keys(props.filters).map((key: string) => {
        if (
          key === "page" ||
          key === "id" ||
          props.filters[key as keyof typeof props.filters] === "" ||
          props.filters[key as keyof typeof props.filters] === undefined
        )
          return "";
        return `${key}=${props.filters[key as keyof typeof props.filters]}`;
      });
      const filters =
        filtersQuery.length > 0
          ? `${filtersQuery.filter((v) => v !== "").join("&")}`
          : "";
      if (filters.includes("categories")) {
        navigate(`/products?${filters}`);
        return;
      }
      props.categories.forEach((category) => {
        fetch(
          `https://api.ybs-closet.com/products?pageSize=10&subCategories=${false}&categories=${
            category.id
          }&page=${
            categoriesData && categoriesData[category.id]
              ? categoriesData[category.id].currentPage
              : 1
          }&${filters}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (
              categoriesData && categoriesData[category.id]
                ? categoriesData[category.id].currentPage === 1
                : true
            ) {
              const firstPageData = data;
              fetch(
                `https://api.ybs-closet.com/products/count?categories=${
                  category.id
                }&subCategories=${false}&page=${
                  categoriesData && categoriesData[category.id]
                    ? categoriesData[category.id].currentPage
                    : 1
                }&${filters}`
              )
                .then((res) => res.json())
                .then((data) => {
                  setCategoriesData((prev) => ({
                    ...prev,
                    [category.id]: {
                      ...(prev[category.id] || {}),
                      totalItems: data.count,
                      currentPage: 1,
                      items: firstPageData,
                      name: category.name || "",
                    },
                  }));
                });
            } else {
              setCategoriesData((prev) => ({
                ...prev,
                [category.id]: {
                  name: category.name || "",
                  totalItems: data.totalItems || 0,
                  currentPage: data.currentPage || 1,
                  items: data || [],
                },
              }));
            }
          })
          .catch((err) => {});
      });
      fetch(
        `https://api.ybs-closet.com/products?pageSize=10&subCategories=${false}&deals=true&page=${
          dealsData ? dealsData.currentPage : 1
        }&${filters}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (dealsData ? dealsData.currentPage === 1 : true) {
            const firstPageData = data;
            fetch(
              `https://api.ybs-closet.com/products/count?deals=true&subCategories=${false}&page=${
                dealsData ? dealsData.currentPage : 1
              }&${filters}`
            )
              .then((res) => res.json())
              .then((data) => {
                setDealsData({
                  totalItems: data.count,
                  currentPage: 1,
                  items: firstPageData,
                });
              });
          } else {
            setDealsData({
              totalItems: data.totalItems || 0,
              currentPage: data.currentPage || 1,
              items: data || [],
            });
          }
        });
    },
    // eslint-disable-next-line
    [props.categories, props.filters]
  );

  return (
    <>
      <Helmet>
        <title>YB's Closet</title>
        <meta
          name="description"
          content="At YB's Closet, we specialize in buying and selling a curated selection of sneakers, clothing, and accessories from a variety of brands.
                  Our mission is to provide our customers with access to the latest trends and timeless classics, all in one place."
        />
      </Helmet>
      {props.categories.length === Object.keys(categoriesData).length ? (
        <>
          {dealsData && dealsData.totalItems > 0 ? (
            <Items
              key="deals"
              items={dealsData.items}
              title="Deals"
              changePageTitle={false}
              totalItems={dealsData.totalItems}
              currentPage={dealsData.currentPage}
              addToCart={props.addToCart}
              cartItemIds={props.cartItemIds}
              updatePage={(page) => {
                fetch(
                  `https://api.ybs-closet.com/products?pageSize=${pageSize}&subCategories=${false}&deals=${true}&page=${page}`
                )
                  .then((res) => res.json())
                  .then((data) => {
                    setDealsData((prev) => ({
                      totalItems: prev?.totalItems || 0,
                      currentPage: page,
                      items: data || [],
                    }));
                  });
              }}
              pageSize={pageSize}
            />
          ) : null}
          {Object.keys(categoriesData)
            .sort((a, b) => {
              return (
                categoriesData[b].totalItems - categoriesData[a].totalItems
              );
            })
            .map((category) => {
              const categoryObj = categoriesData[category];
              if (!categoryObj || categoryObj.totalItems === 0) {
                return null;
              } else {
                return (
                  <Items
                    key={categoryObj.name}
                    items={categoryObj.items}
                    title={categoryObj.name}
                    changePageTitle={false}
                    totalItems={categoryObj.totalItems}
                    currentPage={categoryObj.currentPage}
                    addToCart={props.addToCart}
                    cartItemIds={props.cartItemIds}
                    updatePage={(page) => {
                      fetch(
                        `https://api.ybs-closet.com/products?pageSize=${pageSize}&subCategories=${false}&categories=${category}&page=${page}`
                      )
                        .then((res) => res.json())
                        .then((data) => {
                          setCategoriesData((prev) => ({
                            ...prev,
                            [category]: {
                              name: prev[category].name || "",
                              totalItems: prev[category].totalItems || 0,
                              currentPage: page,
                              items: data || [],
                            },
                          }));
                        });
                    }}
                    pageSize={pageSize}
                  />
                );
              }
            })}
        </>
      ) : (
        <div className="loader">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default Home;
