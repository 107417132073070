import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminPanel from "./components/admin/Index";
import "./App.css";
import Store from "./components/site/Store";
import ConfigProvider from "antd/lib/config-provider";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";

const App: React.FC = () => {
  // const [isDarkMode, setIsDarkMode] = useState(
  //   localStorage.getItem("darkMode") === "true" ||
  //     (!("darkMode" in localStorage) &&
  //       window.matchMedia("(prefers-color-scheme: dark)").matches)
  // );
  const isDarkMode = true;

  // React.useEffect(() => {
  //   const handleColorSchemeChange = (event: MediaQueryListEvent) => {
  //     setIsDarkMode(event.matches);
  //   };

  //   const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   colorSchemeQuery.addEventListener("change", handleColorSchemeChange);

  //   return () => {
  //     colorSchemeQuery.removeEventListener("change", handleColorSchemeChange);
  //   };
  // }, []);

  ReactGA.initialize("G-GM0KMP0QMM");
  ReactGA.gtag("config", "G-GM0KMP0QMM", {
    page_path: window.location.pathname,
  });

  return (
    <div
      className={"container" + (isDarkMode ? " dark-theme" : " light-theme")}
    >
      <HelmetProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#7d6900",
              colorBgBase: isDarkMode ? "#1e1e1e" : "#ffffff",
              colorTextBase: "#ffffff",
              colorText: "#ffffff",
              colorTextPlaceholder: "#ffffff",
              colorBorder: "#4b4b4b",
              colorBorderSecondary: "#4b4b4b",
              colorBgBlur: !isDarkMode ? "#1e1e1e" : "#ffffff",
              colorBorderBg: !isDarkMode ? "#1e1e1e" : "#ffffff",
              controlOutline: "rgba(125, 104, 0, 0.27)",
              fontFamily: `"Noto Sans", sans-serif`,
            },
          }}
        >
          <Router>
            <Routes>
              <Route path="/admin/*" element={<AdminPanel />} />
              <Route path="/*" element={<Store />} />
            </Routes>
          </Router>
        </ConfigProvider>
      </HelmetProvider>
    </div>
  );
};

export default App;
