import React, { useEffect, useState } from "react";
import { DashboardList, itemType, itemsType, propsType } from "./DashboardList";
import Form from "./Form";
import "./styles/dashboard.css";
import icons from "../assets";
import Spin from "antd/lib/spin";

export type dashboardRequest = {
  id?: string;
  type: itemType;
  name?: string;
  description?: string;
  image?: string;
  images?: {
    uid: string;
    url: string;
  }[];
  parentId?: string;
  categoryId?: string;
  brandId?: string;
  productId?: string;
  size?: string;
  isNew?: boolean;
  originalPrice?: number;
  buyPrice?: number;
  sellPrice?: number;
  isSold?: boolean;
  quantity?: number;
  deal?: boolean;
};

export type itemsContainer = {
  categories?: propsType;
  brands?: propsType;
  products?: propsType;
  inventory?: propsType;
};

export type dataType = {
  categories?: {
    name: string;
    value: string;
  }[];
  brands?: {
    name: string;
    value: string;
  }[];
  products?: {
    name: string;
    value: string;
  }[];
};

const Dashboard: React.FC = () => {
  const [items, setItems] = useState<itemsContainer>({});
  const [data, setData] = useState<dataType>({});
  const [currentDashboard, setCurrentDashboard] = useState<
    propsType | undefined
  >(undefined);

  // eslint-disable-next-line
  useEffect(() => getData(), []);

  useEffect(() => {
    if (
      items.categories &&
      currentDashboard !== undefined &&
      currentDashboard.type === items.categories?.type
    ) {
      setCurrentDashboard(items.categories);
    }
  }, [items.categories, currentDashboard]);

  useEffect(() => {
    if (
      items.brands &&
      currentDashboard !== undefined &&
      currentDashboard.type === items.brands?.type
    ) {
      setCurrentDashboard(items.brands);
    }
  }, [items.brands, currentDashboard]);

  useEffect(() => {
    if (
      items.products &&
      currentDashboard !== undefined &&
      currentDashboard.type === items.products?.type
    ) {
      setCurrentDashboard(items.products);
    }
  }, [items.products, currentDashboard]);

  useEffect(() => {
    if (
      items.inventory &&
      currentDashboard !== undefined &&
      currentDashboard.type === items.inventory?.type
    ) {
      setCurrentDashboard(items.inventory);
    }
  }, [items.inventory, currentDashboard]);
  const [openForms, setOpenForms] = useState<dashboardRequest[]>([]);
  const [currentForm, setCurrentForm] = useState<dashboardRequest | undefined>(
    undefined
  );

  enum dashboards {
    categories = "categories",
    brands = "brands",
    products = "products",
    inventory = "inventory",
  }

  const menuLinks = [
    {
      name: "Categories",
      iconSVG: icons.categories,
      enum: dashboards.categories,
    },
    { name: "Brands", iconSVG: icons.brands, enum: dashboards.brands },
    { name: "Products", iconSVG: icons.products, enum: dashboards.products },
    {
      name: "Inventory",
      iconSVG: icons.statistics,
      enum: dashboards.inventory,
    },
  ];

  const getItemByEnum = (enumValue: dashboards) => {
    switch (enumValue) {
      case dashboards.categories:
        return items.categories;
      case dashboards.brands:
        return items.brands;
      case dashboards.products:
        return items.products;
      case dashboards.inventory:
        return items.inventory;
      default:
        return undefined;
    }
  };

  const addForm = (formType: itemType, parentId?: string) => {
    let newForm: dashboardRequest = {
      id: undefined,
      type: formType,
      name: "",
      parentId: parentId,
      description: "",
      size: "",
    };
    setOpenForms([newForm]);
    setCurrentForm(newForm);
  };

  const typeToURL = (type: itemType) => {
    switch (type) {
      case itemType.Category:
        return "categories";
      case itemType.Brand:
        return "brands";
      case itemType.Product:
        return "products";
      case itemType.Inventory:
        return "inventory";
      default:
        return "";
    }
  };

  const editForm = async (type: itemType, id: string) => {
    try {
      let newForm: dashboardRequest | null = null;
      const response = await fetch(
        `https://api.ybs-closet.com/${typeToURL(type)}/dashboard/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        newForm = { ...data, type: type };
      }
      if (newForm) {
        setOpenForms([...openForms, newForm]);
        setCurrentForm(newForm);
      }
    } catch (error) {
      // Handle the error
    }
  };

  const duplicateForm = async (type: itemType, id: string) => {
    try {
      let newForm: dashboardRequest | null = null;
      const response = await fetch(
        `https://api.ybs-closet.com/${typeToURL(type)}/dashboard/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        newForm = { ...data, type: type, id: undefined };
      }
      if (newForm) {
        setOpenForms([...openForms, newForm]);
        setCurrentForm(newForm);
      }
    } catch (error) {
      // Handle the error
    }
  };

  async function dataURLtoBlob(dataurl: string) {
    try {
      if (dataurl.startsWith("blob:")) {
        const response = await fetch(dataurl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        return blob;
      } else {
        var arr = dataurl.split(",");
        if (arr.length > 1) {
          let mime: string | undefined = arr[0].match(/:(.*?);/)?.[1];
          let bstr: string = atob(arr[1]);
          let n: number = bstr.length;
          let u8arr: Uint8Array = new Uint8Array(n);
          for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
          }
          return new Blob([u8arr], { type: mime });
        }
      }
    } catch {
      return undefined;
    }
  }

  function unfoldItems(items: itemsType[]) {
    let newItems: {
      name: string;
      value: string;
    }[] = [];
    if (!items) return newItems;
    items.forEach((item) => {
      newItems.push({
        name: item.name,
        value: item.id as string,
      });
      if (item.items) {
        newItems = newItems.concat(unfoldItems(item.items));
      }
    });
    return newItems;
  }

  const dashboardFunctions = {
    addItem: (type: itemType) => {
      addForm(type);
    },
    addSubItem: (type: itemType, parentId: string) => {
      addForm(type, parentId);
    },
    editItem: (type: itemType, id: string) => {
      editForm(type, id);
    },
    duplicateItem: (type: itemType, id: string) => {
      duplicateForm(type, id);
    },
    deleteItem: (type: itemType, id: string) => {
      switch (type) {
        case itemType.Category:
          fetch(`https://api.ybs-closet.com/categories/${id}`, {
            method: "DELETE",
            credentials: "include",
          })
            .then((response) => {
              if (response.ok) {
                updateCategories();
              }
            })
            .catch((error) => {
              // Handle the error
            });
          break;
        case itemType.Brand:
          fetch(`https://api.ybs-closet.com/brands/${id}`, {
            method: "DELETE",
            credentials: "include",
          })
            .then((response) => {
              if (response.ok) {
                updateBrands();
              }
            })
            .catch((error) => {
              // Handle the error
            });
          break;
        case itemType.Product:
          fetch(`https://api.ybs-closet.com/products/${id}`, {
            method: "DELETE",
            credentials: "include",
          })
            .then((response) => {
              if (response.ok) {
                updateProducts();
              }
            })
            .catch((error) => {
              // Handle the error
            });
          break;
        case itemType.Inventory:
          fetch(`https://api.ybs-closet.com/inventory/${id}`, {
            method: "DELETE",
            credentials: "include",
          })
            .then((response) => {
              if (response.ok) {
                updateInventory();
              }
            })
            .catch((error) => {
              // Handle the error
            });
          break;
        default:
          break;
      }
    },
  };

  const updateCategories = () => {
    fetch("https://api.ybs-closet.com/categories/dashboard", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data: propsType) => {
        setItems((prev) => ({
          ...prev,
          categories: {
            ...data,
            ...dashboardFunctions,
          },
        }));
        setData((prev) => ({ ...prev, categories: unfoldItems(data.items) }));
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const updateBrands = () => {
    fetch("https://api.ybs-closet.com/brands/dashboard", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setItems((prev) => ({
          ...prev,
          brands: {
            ...data,
            ...dashboardFunctions,
          },
        }));
        setData((prev) => ({ ...prev, brands: unfoldItems(data.items) }));
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const updateProducts = () => {
    fetch("https://api.ybs-closet.com/products/dashboard", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setItems((prev) => ({
          ...prev,
          products: {
            ...data,
            ...dashboardFunctions,
          },
        }));
        setData((prev) => ({ ...prev, products: unfoldItems(data.items) }));
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const updateInventory = () => {
    fetch("https://api.ybs-closet.com/inventory/dashboard", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setItems((prev) => ({
          ...prev,
          inventory: {
            ...data,
            ...dashboardFunctions,
          },
        }));
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const getData = () => {
    // Fetch categories
    updateCategories();

    // Fetch brands
    updateBrands();

    // Fetch products
    updateProducts();

    // Fetch inventory
    updateInventory();
  };

  return (
    <>
      <div className="dashboard">
        <div className="header">
          <div className="title">
            {"Dashboard" +
              (currentDashboard ? " | " + currentDashboard.name : "")}
          </div>
          <div
            className={"back" + (currentDashboard === undefined ? " hide" : "")}
          >
            <img
              src={icons.returnBack}
              alt="Back"
              onClick={() => {
                setCurrentDashboard(undefined);
              }}
            />
          </div>
        </div>
        {items.brands &&
        items.categories &&
        items.products &&
        items.inventory ? (
          <>
            <div className="dashboard-lists">
              {items.categories && <DashboardList {...items.categories} />}
              {items.brands !== undefined && (
                <DashboardList {...items.brands} />
              )}
              {items.products && <DashboardList {...items.products} />}
              {items.inventory && <DashboardList {...items.inventory} />}
            </div>
            <div
              className={"dashboard-menu" + (currentDashboard ? " hide" : "")}
            >
              {menuLinks.map((link) => (
                <button
                  className="list-item"
                  type="submit"
                  key={link.name}
                  onClick={(e) => {
                    switch (link.enum) {
                      case dashboards.categories:
                        updateCategories();
                        break;
                      case dashboards.brands:
                        updateBrands();
                        break;
                      case dashboards.products:
                        updateProducts();
                        break;
                      case dashboards.inventory:
                        updateInventory();
                        break;
                      default:
                        break;
                    }
                    setCurrentDashboard(getItemByEnum(link.enum));
                  }}
                >
                  <img className="icon" src={link.iconSVG} alt={link.name} />
                  <div className="name">{link.name}</div>
                </button>
              ))}
            </div>
            {currentDashboard && (
              <div className="mobile">
                <DashboardList {...currentDashboard}></DashboardList>
              </div>
            )}
          </>
        ) : (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
      {currentForm !== undefined && (
        <Form
          data={data}
          currentForm={currentForm}
          updateForm={(updated: dashboardRequest) => {
            setCurrentForm(updated);
          }}
          closeForm={() => {
            setOpenForms(
              openForms.filter((form) => form.id !== currentForm?.id)
            );
            setCurrentForm(undefined);
          }}
          confirmForm={async (updated: dashboardRequest) => {
            let url = "";
            let formData = new FormData();
            let blob;
            switch (updated.type) {
              case itemType.Category:
                url =
                  "https://api.ybs-closet.com/categories" +
                  (updated.id ? "/" + updated.id : "");
                return await fetch(url, {
                  method: updated.id ? "PUT" : "POST",
                  credentials: "include",
                  body: JSON.stringify(updated),
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((response) => {
                    if (response.ok) {
                      updateCategories();
                    }
                    return response.ok;
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    return false;
                  });
              case itemType.Brand:
                formData.append("name", updated.name as string);
                formData.append("description", updated.description as string);
                if (updated.parentId !== undefined)
                  formData.append("parentId", updated.parentId as string);
                if (updated.image) {
                  blob = await dataURLtoBlob(updated.image as string);
                  if (blob) formData.append("image", blob);
                }
                url =
                  "https://api.ybs-closet.com/brands" +
                  (updated.id ? "/" + updated.id : "");
                return await fetch(url, {
                  method: updated.id ? "PUT" : "POST",
                  body: formData,
                  credentials: "include",
                })
                  .then((response) => {
                    if (response.ok) {
                      updateBrands();
                    }
                    return response.ok;
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    return false;
                  });
              case itemType.Product:
                if (
                  !updated.name ||
                  !updated.categoryId ||
                  !updated.brandId ||
                  !updated.image
                )
                  return false;
                formData.append("name", updated.name as string);
                formData.append("description", updated.description as string);
                formData.append("categoryId", updated.categoryId as string);
                formData.append("brandId", updated.brandId as string);
                formData.append(
                  "originalPrice",
                  updated.originalPrice ? updated.originalPrice.toString() : "0"
                );
                if (updated.image && updated.image.includes("blob:") === true) {
                  blob = await dataURLtoBlob(updated.image as string);
                  if (!blob) return false;
                  formData.append("image", blob);
                }
                url =
                  "https://api.ybs-closet.com/products" +
                  (updated.id ? "/" + updated.id : "");
                return await fetch(url, {
                  method: updated.id ? "PUT" : "POST",
                  body: formData,
                  credentials: "include",
                })
                  .then((response) => {
                    if (response.ok) {
                      updateProducts();
                    }
                    return response.ok;
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    return false;
                  });
              case itemType.Inventory:
                return await fetch(
                  "https://api.ybs-closet.com/inventory" +
                    (updated.id ? "/" + updated.id : ""),
                  {
                    method: updated.id ? "PUT" : "POST",
                    body: JSON.stringify(updated),
                    headers: {
                      "Content-Type": "application/json",
                    },
                    credentials: "include",
                  }
                )
                  .then((response) => {
                    if (response.ok) {
                      updateInventory();
                    }
                    return response.ok;
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    return false;
                  });
              default:
                return false;
            }
          }}
        />
      )}
    </>
  );
};

export default Dashboard;
