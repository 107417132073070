import React from "react";
import "./styles/content-page-container.css";
import { Helmet } from "react-helmet-async";

const FAQ: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>YB's Closet - FAQ's</title>
        <meta
          name="description"
          content="Frequently Asked Questions (FAQ) for YB's Closet, a premier destination for top brands in Brooklyn, NYC. Learn about payment methods, shipping rates, returns, and more."
        />
      </Helmet>
      <div className="content-page-container">
        <h1>Frequently Asked Questions (FAQ)</h1>

        <h2>1. What payment methods do you accept?</h2>
        <p>
          We only accept cash and Zelle for payments.
          <br />
          Please ensure you have the necessary payment method available when
          making a purchase.
        </p>

        <h2>2. How long does it take to process and ship my order?</h2>
        <p>
          All packages will be shipped out within 1-3 business days after your
          order is placed.
          <br />
          Please note that orders are not processed or shipped on weekends or
          holidays.
        </p>

        <h2>3. What are the shipping rates?</h2>
        <p>
          We offer a flat rate shipping fee of $10 for all orders within the
          United States.
        </p>

        <h2>4. Do you accept returns or exchanges?</h2>
        <p>
          All sales are final.
          <br />
          We do not accept returns or exchanges.
          <br />
          Please ensure that you review your order carefully before making a
          purchase.
        </p>

        <h2>5. Do you offer a pick-up in store option?</h2>
        <p>
          Yes, we offer a pick-up in store option available at the check-out
          page for prepaid orders by Zelle only (no cash).
          <br />
          Please select this option during checkout if you prefer to pick up
          your order in-store.
        </p>

        <h2>6. What should I do if my package is lost or stolen?</h2>
        <p>
          YB's Closet is not responsible for any lost or stolen packages.
          <br />
          Once your order has been shipped and tracking information is provided,
          it is the responsibility of the shipping carrier to deliver your
          package.
          <br />
          If you experience any issues with delivery, please contact the carrier
          directly.
        </p>

        <h2>7. How can I contact you?</h2>
        <p>If you have any questions or concerns, please contact us at:</p>
        <p>
          YB's Closet
          <br />
          2539 Ocean Avenue
          <br />
          Brooklyn, NY 11229
          <br />
          Email:{" "}
          <a href="mailto:support@ybs-closet.com">support@ybs-closet.com</a>
          <br />
          Instagram:{" "}
          <a href="https://www.instagram.com/ybsclosetnycc">@ybsclosetnycc</a>
        </p>
      </div>
    </React.Fragment>
  );
};

export default FAQ;
