import Radio from "antd/lib/radio";
import React, { useEffect, useState } from "react";
import "./styles/filter.css";
import { categoriesType, brandsType } from "./Store";
import Select from "antd/lib/select";
import TreeSelect from "antd/lib/tree-select";
import Button from "antd/lib/button";
import { FilterOutlined, ClearOutlined } from "@ant-design/icons";

const Filter: React.FC<{
  data: {
    brands: brandsType[];
    categories: categoriesType[];
    sizes: string[];
  };
  values: {
    brands?: string;
    categories?: string;
    sizes?: string;
    isNew?: boolean;
  };
  setFilters: (filters: {
    id?: string;
    page?: string;
    isNew?: boolean;
    brands?: string;
    categories?: string;
    sizes?: string;
  }) => void;
  onClose: () => void;
}> = (props) => {
  const [sizes, setSizes] = useState<string[]>(
    props.values.sizes?.split(",") || []
  );
  const [isNew, setIsNew] = useState<boolean | undefined>(props.values.isNew);
  const [brands, setBrands] = useState<string[]>(
    props.values.brands?.split(",") || []
  );
  const [categories, setCategories] = useState<string[]>(
    props.values.categories?.split(",") || []
  );
  useEffect(() => {
    setSizes(props.values.sizes?.split(",") || []);
    setIsNew(props.values.isNew);
    setBrands(props.values.brands?.split(",") || []);
    setCategories(props.values.categories?.split(",") || []);
  }, [props.values]);

  return (
    <div className="filter-container" key="condition">
      <div className="filter" key="brands">
        <div className="title">Brands</div>
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="All Brands"
          onChange={(value) => {
            // props.onFilterChange("brands", value.join(","));
            setBrands(value);
          }}
          options={props.data.brands.map((brand) => ({
            label: brand.name,
            value: brand.id,
          }))}
          value={brands}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="filter" key="categories">
        <div className="title">Categories</div>
        <TreeSelect
          style={{ width: "100%" }}
          allowClear
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={props.data.categories.map((category) => ({
            title: category.name,
            value: category.id,
            key: category.id,
            children: category.subItems.map((sub) => ({
              title: sub.name,
              value: sub.id,
              key: sub.id,
            })),
          }))}
          placeholder="All Categories"
          treeDefaultExpandAll
          onChange={(value) => {
            // props.onFilterChange("categories", value.join(","));
            setCategories(value);
          }}
          multiple
          value={categories}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="filter" key="condition">
        <div className="title">Item's Condition</div>
        <Radio.Group
          value={isNew}
          onChange={(choice) => {
            setIsNew(choice.target.value);
          }}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio value={undefined}>All</Radio>
          <Radio value={true}>Brand New</Radio>
          <Radio value={false}>Preowned</Radio>
        </Radio.Group>
      </div>
      <div className="filter" key="sizes">
        <div className="title">Sizes</div>
        <Select
          style={{ width: "100%" }}
          allowClear
          placeholder="All Sizes"
          onChange={(value) => {
            setSizes(value);
          }}
          options={props.data.sizes
            .map((size) => ({
              label: size,
              value: size,
            }))
            .sort((a, b) => parseFloat(a.value) - parseFloat(b.value))}
          value={sizes}
          mode="multiple"
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="buttons">
        <Button
          type="primary"
          icon={<FilterOutlined />}
          onClick={() => {
            props.setFilters({
              brands: brands.join(","),
              categories: categories.join(","),
              sizes: sizes.join(","),
              isNew: isNew,
              page: "1",
              id: undefined,
            });
            props.onClose();
          }}
          disabled={
            brands.join(",") ===
              (props.values.brands ? props.values.brands : "") &&
            categories.join(",") ===
              (props.values.categories ? props.values.brands : "") &&
            sizes.join(",") ===
              (props.values.sizes ? props.values.sizes : "") &&
            isNew === props.values.isNew
          }
          style={{ width: "100%" }}
        >
          Search
        </Button>
        <Button
          type="primary"
          icon={<ClearOutlined />}
          disabled={
            !brands.length &&
            !categories.length &&
            !sizes.length &&
            isNew === undefined
          }
          onClick={() => {
            props.setFilters({
              page: "1",
              id: undefined,
              brands: undefined,
              categories: undefined,
              sizes: undefined,
              isNew: undefined,
            });
            props.onClose();
          }}
          style={{ width: "100%" }}
        >
          Clear Filter
        </Button>
      </div>
    </div>
  );
};

export default Filter;
