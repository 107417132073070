import React, { useEffect } from "react";
import "./styles/request-purchase.css";
import { StoreCartInterface } from "./Cart";
import assets from "../assets";
import { Radio } from "antd";
import Marquee from "react-fast-marquee";

const RequestPurchase: React.FC<{
  id?: string;
  cartItems: StoreCartInterface[];
  removeItem: (id: string) => Promise<boolean>;
  requestPurchase: (
    cart: string[],
    customer: {
      name: string;
      email: string;
      phone: string;
      instagram: string;
      address: string;
      city: string;
      state: string;
      zipCode: string;
      zelle: string;
    },
    delivery: boolean
  ) => Promise<boolean>;
}> = (props) => {
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [requestApproved, setRequestApproved] = React.useState<boolean>(false);
  const [requestedItem, setRequestedItem] = React.useState<
    StoreCartInterface | undefined
  >(undefined);

  const [zelleInfo, setZelleInfo] = React.useState<
    "email" | "phone" | "custom"
  >("email");

  const [shipping, setShipping] = React.useState<boolean>(true);

  useEffect(() => {
    if (props.id) {
      fetch(`https://api.ybs-closet.com/inventory/${props.id}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          setRequestedItem(data);
          setRequesting(false);
          setRequestApproved(false);
        });
    } else {
      setRequestedItem(undefined);
      setRequesting(false);
      setRequestApproved(false);
    }
  }, [props.id]);

  const cartItems = requestedItem ? [requestedItem] : props.cartItems;

  const taxRate = 0.08875; // New York tax rate
  const shippingFee = 10; // Flat rate shipping fee
  const totalTaxed = cartItems.reduce(
    (acc, item) => acc + (item.price < 110 ? 0 : item.price),
    0
  );
  const totalTaxes = totalTaxed * taxRate;

  const totalPriceBeforeTaxes = cartItems.reduce(
    (acc, item) => acc + item.price,
    0
  );

  const totalPriceAfterTaxes =
    totalPriceBeforeTaxes + totalTaxes + (shipping ? shippingFee : 0);

  return !requestApproved ? (
    <div className="request-purchase">
      <h1>Request a Purchase</h1>
      <div className="items">
        {cartItems.map((item) => (
          <div className="item" key={item.inventoryId}>
            <img src={item.image} alt={item.name} />
            <div className="info">
              <Marquee gradient={false} speed={30}>
                <h3 style={{ marginRight: "1rem" }}>{item.name}</h3>
              </Marquee>
              <p>Size: {item.size}</p>
              <p>Condition: {item.condition}</p>
              <p>Price: ${item.price}</p>
            </div>
            {requestedItem === undefined ? (
              <div className="actions">
                <button
                  onClick={async () => {
                    // Remove item from cart
                    await props.removeItem(item.inventoryId);
                  }}
                >
                  <img src={assets.removeFromCartBtn} alt="Remove" />
                </button>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="total">
        <h2>Total Price: ${totalPriceBeforeTaxes.toFixed(2)}</h2>
        <h2>Taxes (New York): ${totalTaxes.toFixed(2)}</h2>
        {
          // Only show shipping fee if shipping
          shipping ? <h2>Shipping Fee: ${shippingFee.toFixed(2)}</h2> : null
        }
        <h2>Total Price after Taxes: ${totalPriceAfterTaxes.toFixed(2)}</h2>
      </div>
      <p>
        After submitting your request, we will review your items and contact you
        to proceed with the purchase within 24 hours. <br />* Some of the
        products may not be available for purchase by the time of contact.
      </p>
      <form className="purchase-form">
        <h2>Personal Information</h2>
        <div className="form-row">
          <label htmlFor="name">*Name:</label>
          <input id="name" name="name" required />
        </div>
        <div className="form-row">
          <label htmlFor="email">*Email:</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-row">
          <label htmlFor="phone">*Phone:</label>
          <input type="tel" id="phone" name="phone" required />
        </div>
        <div className="form-row">
          <label htmlFor="instagram">Instagram:</label>
          <input id="instagram" name="instagram" />
        </div>
        <div className="form-row">
          <Radio.Group
            id="pickup"
            defaultValue="delivery"
            onChange={(value) => {
              setShipping(value.target.value === "delivery");
            }}
          >
            <Radio.Button value="pickup">Pickup</Radio.Button>
            <Radio.Button value="delivery">Delivery</Radio.Button>
          </Radio.Group>
        </div>
        {shipping ? (
          <>
            <div className="form-row">
              We offer a flat rate shipping fee of ${shippingFee} for all orders
              within the United States.
            </div>
            <div className="form-row">
              <label htmlFor="address">*Address:</label>
              <input id="address" name="address" required></input>
            </div>
            <div className="form-row">
              <label htmlFor="city">*City:</label>
              <input id="city" name="city" required></input>
            </div>
            <div className="form-row">
              <label htmlFor="state">*State:</label>
              <input id="state" name="state" required></input>
            </div>
            <div className="form-row">
              <label htmlFor="zip">*Zip Code:</label>
              <input id="zip" name="zip" required></input>
            </div>
          </>
        ) : null}
        <div className="form-row">
          <label htmlFor="zelle">Zelle:</label>
          <Radio.Group
            id="zelle"
            defaultValue="email"
            onChange={(value) => {
              setZelleInfo(value.target.value);
            }}
          >
            <Radio.Button value="email">Email</Radio.Button>
            <Radio.Button value="phone">Phone</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        {zelleInfo === "custom" ? (
          <div className="form-row">
            {/* <label htmlFor="zelle-info">Zelle Information:</label> */}
            <input id="zelle-info" name="zelle-info" required></input>
          </div>
        ) : null}
        <button
          type="submit"
          onClick={async (e) => {
            e.preventDefault();
            // Submit request
            if (requesting) return;
            const name = (document.getElementById("name") as HTMLInputElement)
              .value;
            const email = (document.getElementById("email") as HTMLInputElement)
              .value;
            const phone = (document.getElementById("phone") as HTMLInputElement)
              .value;
            const instagram = (
              document.getElementById("instagram") as HTMLInputElement
            ).value;

            let address = "";
            let city = "";
            let state = "";
            let zipCode = "";
            let custom = "";

            if (shipping) {
              address = (document.getElementById("address") as HTMLInputElement)
                .value;
              city = (document.getElementById("city") as HTMLInputElement)
                .value;
              state = (document.getElementById("state") as HTMLInputElement)
                .value;
              zipCode = (document.getElementById("zip") as HTMLInputElement)
                .value;
            }
            if (zelleInfo === "custom") {
              custom = (
                document.getElementById("zelle-info") as HTMLInputElement
              ).value;
            }

            let zelleAddress;

            switch (zelleInfo) {
              case "email":
                zelleAddress = email;
                break;
              case "phone":
                zelleAddress = phone;
                break;
              case "custom":
                zelleAddress = custom;
                break;
            }

            setRequesting(true);

            const requestApproved = await props.requestPurchase(
              cartItems.map((item) => item.inventoryId),
              {
                name,
                email,
                phone,
                instagram,
                address,
                city,
                state,
                zipCode: zipCode,
                zelle: zelleAddress,
              },
              shipping
            );
            setRequestApproved(requestApproved);
            setRequesting(false);
          }}
        >
          Submit Request
        </button>
      </form>
    </div>
  ) : (
    <div className="request-approved">
      <img src={assets.requestSubmitted} alt="Request Approved" />
      <h1>Request Approved</h1>
      <p>
        Your request has been approved. We will contact you within 24 hours to
        proceed with the purchase.
      </p>
    </div>
  );
};

export default RequestPurchase;
