import React from "react";
import "./styles/content-page-container.css";
import { Helmet } from "react-helmet-async";

const ReturnsPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>YB's Closet - Returns Policy</title>
        <meta
          name="description"
          content="Returns Policy for YB's Closet, a premier destination for top brands in Brooklyn, NYC. Learn about our returns and exchanges policy and how to contact us with any questions or concerns."
        />
      </Helmet>
      <div className="content-page-container">
        <h1>Returns Policy</h1>

        <p>
          Thank you for shopping with YB's Closet.
          <br />
          Please review our returns policy below.
        </p>

        <h2>1. No Returns or Exchanges</h2>
        <p>
          All sales are final.
          <br />
          We do not accept returns or exchanges.
          <br />
          Please ensure that you review your order carefully before making a
          purchase.
        </p>

        <h2>2. Contact Us</h2>
        <p>
          If you have any questions or concerns about our returns policy, please
          contact us at:
        </p>
        <p>
          YB's Closet
          <br />
          2539 Ocean Avenue
          <br />
          Brooklyn, NY 11229
          <br />
          Email:{" "}
          <a href="mailto:support@ybs-closet.com">support@ybs-closet.com</a>
          <br />
          Instagram:{" "}
          <a href="https://www.instagram.com/ybsclosetnycc">@ybsclosetnycc</a>
        </p>
      </div>
    </>
  );
};

export default ReturnsPolicy;
