import React from "react";
import "./styles/content-page-container.css";
import { Helmet } from "react-helmet-async";

const ShippingPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>YB's Closet - Shipping Policy</title>
        <meta
          name="description"
          content="Shipping Policy for YB's Closet, a premier destination for top brands in Brooklyn, NYC. Learn about our processing time, shipping rates, delivery time, and more."
        />
      </Helmet>
      <div className="content-page-container">
        <h1>Shipping Policy</h1>

        <p>
          Thank you for shopping with YB's Closet. We strive to ensure that your
          order is processed and shipped in a timely manner. Please review our
          shipping policy below.
        </p>

        <h2>1. Processing Time</h2>
        <p>
          All packages will be shipped out within 1-3 business days after your
          order is placed. Please note that orders are not processed or shipped
          on weekends or holidays.
        </p>

        <h2>2. Shipping Rates</h2>
        <p>
          We offer a flat rate shipping fee of $10 for all orders within the
          United States.
        </p>

        <h2>3. Delivery Time</h2>
        <p>
          Delivery times may vary depending on your location and the shipping
          carrier. Please allow additional time for your order to arrive due to
          potential delays with the carrier.
        </p>

        <h2>4. No Returns or Exchanges</h2>
        <p>
          All sales are final. We do not accept returns or exchanges. Please
          ensure that you review your order carefully before making a purchase.
        </p>

        <h2>5. Lost or Stolen Packages</h2>
        <p>
          YB's Closet is not responsible for any lost or stolen packages. Once
          your order has been shipped and tracking information is provided, it
          is the responsibility of the shipping carrier to deliver your package.
          If you experience any issues with delivery, please contact the carrier
          directly.
        </p>

        <h2>6. Contact Us</h2>
        <p>
          If you have any questions or concerns about our shipping policy,
          please contact us at:
        </p>
        <p>
          YB's Closet
          <br />
          2539 Ocean Avenue
          <br />
          Brooklyn, NY 11229
          <br />
          Email:{" "}
          <a href="mailto:support@ybs-closet.com">support@ybs-closet.com</a>
          <br />
          Instagram:{" "}
          <a href="https://www.instagram.com/ybsclosetnycc">@ybsclosetnycc</a>
        </p>
      </div>
    </>
  );
};

export default ShippingPolicy;
